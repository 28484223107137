import { IconCheck, IconInfoCircle } from "@tabler/icons-react";
import clsx from "clsx";
import { useContext, useRef } from "react";
import { keyEnter } from "../Utils";
import { ModalContext } from "../contexts/Contexts";
import { Button, shake } from "./Button";
import Tooltip from "./Tooltip";

/**
 * @param {{
 *    id: string,
 *    label: string,
 *    state: UseState<boolean>,
 *    info: JSX.Element,
 *    disabled: boolean,
 *    after: any,
 * }} props
 */
const CheckBox = (props) => {
  const { id, label, state, info, disabled, after } = props;
  const [selected, setSelected] = state;
  const { contentElement } = useContext(ModalContext);
  const inactive = !selected || disabled;
  const tooltipRef = useRef(null);
  const checkRef = useRef(null);

  function handleToggleBtn(event) {
    if (disabled) {
      shake(event.target);
      return;
    }
    setSelected(!selected);
  }

  function infoClick() {
    const tooltip = tooltipRef.current;
    tooltip?.state.isVisible ? tooltip?.hide() : tooltip?.show();
  }

  return (
    <span
      className={clsx("check-box", id, !inactive && "selected")}
      onClick={() => checkRef.current?.click()}
      tabIndex={-1}
    >
      <Button
        id={id}
        className="check"
        icon={<IconCheck stroke={3} />}
        depth="0.4rem"
        fontSize="1rem"
        variant={inactive && "secondary"}
        onClick={handleToggleBtn}
        ref={checkRef}
      />
      <label htmlFor={id} style={{ marginRight: !info && "auto" }}>
        {label}
      </label>
      {info && (
        <Tooltip
          className={clsx("info-tooltip", id && `tt-${id}`)}
          content={info}
          appendTo={contentElement}
          trigger="mouseenter focus click"
          delay={[500, 0]}
          placement="right"
          onCreate={(inst) => (tooltipRef.current = inst)}
        >
          <IconInfoCircle
            className="capture-info"
            stroke={2.2}
            tabIndex={0}
            style={{ marginRight: info && "auto" }}
            onKeyDown={(e) => keyEnter(e, infoClick)}
          />
        </Tooltip>
      )}
      {after}
    </span>
  );
};

export default CheckBox;
