import clsx from "clsx";
import { ClickScrollPlugin, OverlayScrollbars } from "overlayscrollbars";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import zenScroll from "zenscroll";
import { pause } from "../Utils";
import "../styles/bubble.css";

/** @type {ForwardRef<TextBubbleProps, HTMLDivElement>} */
const TextBubble = forwardRef((props, ref) => {
  const {
    tailPlacement,
    onResize,
    bubbleShown,
    indicateDelayMS,
    indicateOn,
    indicateScroll,
    alwaysShowScroll,
    innerHtml,
    innerContent,
    detached,
    className,
    headerButtons,
    tail,
    tailPercentOffset,
    tailAbsOffset,
  } = props;

  const [bubble, setBubble] = useState(null);
  const content = useRef(null);
  const root = useRef(null);
  const placement = `place-${tailPlacement || "bottom"}`;

  OverlayScrollbars.plugin(ClickScrollPlugin);
  const bubbleResize = useRef(null);
  const smoothScroller = zenScroll.createScroller(bubble);

  // prettier-ignore
  useImperativeHandle(ref, () => {
    return content.current;
  }, [bubble]);

  useEffect(() => {
    if (bubbleResize.current) {
      bubbleResize.current.disconnect();
    }
    if (typeof onResize === "function" && bubble) {
      bubbleResize.current = new ResizeObserver(onResize);
      bubbleResize.current.observe(bubble);
    }
  }, [bubble, onResize]);

  useEffect(() => {
    if (!bubble || !bubbleShown) return;
    if (indicateDelayMS) {
      pause(indicateDelayMS).then(showOverflow);
    } else showOverflow();
  }, [...indicateOn, bubbleShown, bubble]);

  function initContainer() {
    if (!root.current) return;
    if (root.current.getElement) {
      root.current = root.current.getElement();
    }
    setBubble(root.current.childNodes[1]);
  }

  async function showOverflow() {
    if (!bubble || !indicateScroll) return;
    await pause(400);
    const height = bubble.scrollHeight - bubble.clientHeight;
    const scrollDuration = height * 3;
    smoothScroller.toY(height, scrollDuration, async () => {
      await pause(500);
      smoothScroller.toY(0, scrollDuration);
    });
  }

  const textComponent = (
    <OverlayScrollbarsComponent
      options={{
        scrollbars: {
          clickScroll: true,
          autoHide: alwaysShowScroll ? "never" : "move",
        },
        overflow: {
          x: "hidden",
        },
      }}
      events={{
        initialized: initContainer,
      }}
      ref={root}
      defer
    >
      <div
        className="text-bubble-content"
        {...(innerHtml && {
          dangerouslySetInnerHTML: { __html: innerHtml },
        })}
        ref={content}
      >
        {innerContent}
      </div>
    </OverlayScrollbarsComponent>
  );

  return detached ? (
    textComponent
  ) : (
    <div className={clsx("text-bubble-container", className)}>
      <div className="bubble-overflow-ref" />
      <div className={clsx("text-bubble", placement)}>
        {headerButtons}
        {textComponent}
      </div>
      <div
        className={`text-bubble-tail-${tail || "left"}`}
        style={{
          "--percent-offset": tailPercentOffset,
          "--raw-tail-offset": tailAbsOffset,
        }}
      />
    </div>
  );
});

export default TextBubble;
