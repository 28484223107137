import { MenuItem, Pagination, Radio, Select } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import axios from "axios";
import { clsx } from "clsx";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ServerUrl } from "../../Utils";
import AccordianElement from "../../components/Accordian";
import InputField from "../../components/InputField";
import ProfileModal from "../../components/ProfileModal";
import ProfilePhoto from "../../components/ProfilePhoto";
import ResearchModal from "../../components/ResearcherModal";
import Symbol from "../../components/ResearcherSymbol";
import "../../styles/research-search.css";

export default function SearchUI() {
  document.body.id = "research-search";
  const navigate = useNavigate();

  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const ResearcherUserInfo = {
    firstName: "Andrew",
    lastName: "Hellinger",
    email: "andrew@jhu.edu",
    role: "RESEARCHER",
  };

  const [no_of_users, setNoOfUsers] = useState(10);
  const [userData, setUsersData] = useState([]);
  const [isSAChecked, setIsSAChecked] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState(new Map());
  const [modalState, setModalState] = useState({ action: "enablePostTest" });
  const [isActionsOpen, setIsActionsOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [debouncedSearchValue, setDebouncedSearchValue] = useState("");
  const [page, setPage] = useState(1);
  const [filterOptions, setFilterOptions] = useState({
    task: null,
    study: new Set(),
    completion: new Set(),
  });
  const [searchOn, setSearchOn] = useState("name");

  const [totalPages, setTotalPages] = useState(0);

  function processUserData(users) {
    return users.map((user) => {
      return {
        participantId: user.participantId,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        preTestActivated: user.preTestActivated,
        trainingsActivated: user.trainingsActivated,
        postTestActivated: user.postTestActivated,
        progress: 100,
        dateJoined: user.dateJoined,
        letter:
          user.role === "VALIDATION_STUDY"
            ? "V"
            : user.role === "EXPERIMENT"
              ? "H"
              : user.role === "CONTROL"
                ? "C"
                : user.role === "EXPERIMENT_AM"
                  ? "A"
                  : "V",
      };
    });
  }

  function fetchUsers(setUsersData, url) {
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      redirect: "follow",
    };
    axios(url.href, requestOptions)
      .then((result) => {
        if (result.status !== 200) throw new Error("Failed to fetch users");
        else {
          setUsersData(processUserData(result.data.data.users));
          setTotalPages(result.data.data.totalPages);
        }
      })
      .catch((error) => console.error(error));
  }

  const selectUser = (id, user) =>
    setSelectedUsers((prevSelectedUsers) =>
      new Map(prevSelectedUsers).set(id, user),
    );

  const selectAll = () => {
    const upadtedUsers = new Map();
    setSelectedUsers(() => {
      userData.map((user, i) => {
        upadtedUsers.set(user.participantId, user);
      });
      return upadtedUsers;
    });
  };

  const unselectAll = () => {
    setSelectedUsers(new Map());
  };

  const unSelectUser = (id) => {
    setSelectedUsers((prevSelectedUsers) => {
      const upadtedUsers = new Map(prevSelectedUsers);
      upadtedUsers.delete(id);
      return upadtedUsers;
    });
  };

  function handleCheckbox(userID, userData = null) {
    selectedUsers.has(userID)
      ? unSelectUser(userID)
      : selectUser(userID, userData);
  }

  useEffect(() => {
    // Debounce logic
    // aggregate key strokes and send one
    // request instead of hitting backend
    // on every key stroke

    const handler = setTimeout(() => {
      setDebouncedSearchValue(search);
    }, 1000);

    // Cleanup function to cancel the timeout if value changes
    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  useEffect(() => {
    if (isSAChecked) {
      selectAll();
    } else unselectAll();
  }, [isSAChecked]);

  useEffect(() => {
    const url = new URL(`${ServerUrl}/users/search`);
    const options = [
      filterOptions["task"],
      ...filterOptions["completion"],
      ...filterOptions["study"],
    ];
    options.map((option) => {
      url.searchParams.set(option, true);
    });

    setPage(1);
    url.searchParams.set("page", 1);
    url.searchParams.set("pageSize", no_of_users);
    url.searchParams.set("search", debouncedSearchValue);
    fetchUsers(setUsersData, url);
  }, [filterOptions, no_of_users, debouncedSearchValue]);

  useEffect(() => {
    const url = new URL(`${ServerUrl}/users/search`);
    const options = [
      filterOptions["task"],
      ...filterOptions["completion"],
      ...filterOptions["study"],
    ];
    options.map((option) => {
      url.searchParams.set(option, true);
    });

    url.searchParams.set("pageSize", no_of_users);
    url.searchParams.set("page", page);
    url.searchParams.set("search", debouncedSearchValue);
    fetchUsers(setUsersData, url);
  }, [page]);

  const controlProps = (task) => {
    return {
      checked: filterOptions.task === task,
      sx: { color: "#A7BAD1" },
      onChange: (e) => {
        setFilterOptions((curFilter) => {
          delete curFilter["task"];
          return {
            task: task,
            ...curFilter,
          };
        });
      },
      value: task,
      name: "task-radio-button",
      inputProps: { "aria-label": task },
    };
  };

  return (
    <OverlayScrollbarsComponent
      className="scroll-container"
      options={{
        scrollbars: {
          clickScroll: true,
        },
      }}
      defer
    >
      <div>
        <div className="header">
          <div className="flex-row">
            <ProfilePhoto
              source="https://www.w3schools.com/howto/img_avatar.png"
              onClick={() => setIsProfileOpen(true)}
            />
            <header>Welcome, {ResearcherUserInfo.firstName}! </header>
          </div>
          <div className="flex-row navbar">
            <a href="/research/upload">Upload Users</a>
            <div className="flex-row">
              Search:
              <InputField
                type="text"
                id="search"
                autoComplete="search"
                value={search}
                onChange={() => setSearch(event.target.value)}
              />
              <Select
                sx={{
                  color: "white",
                  svg: { color: "white" },
                  border: "3px solid white !important",
                  borderRadius: "0.4rem",
                  width: "16rem",
                }}
                disabled={filterOptions.task === null}
                labelId="users-page-select"
                id="page-select"
                value={searchOn}
                label="Search On"
                onChange={(e) => setSearchOn(e.target.value)}
              >
                <MenuItem value={"participantId"}>Participant ID</MenuItem>
                <MenuItem value={"name"}>Name</MenuItem>
                <MenuItem value={"email"}>Email</MenuItem>
              </Select>
            </div>
          </div>
        </div>
        <div className="cont">
          <div className="filter-cont">
            <div className="title">Filters</div>
            <div className="seperator"></div>
            <div>
              <div className="filter-title">Task</div>

              <div className="filter-options">
                <div>
                  <Radio {...controlProps("preTest")} size="small" />
                </div>
                <div className="name"> Pre-Test </div>
              </div>
              <div className="filter-options">
                <div>
                  <Radio {...controlProps("training")} size="small" />
                </div>
                <div className="name"> Training </div>
              </div>
              <div className="filter-options">
                <div>
                  <Radio
                    {...controlProps("postTest")}
                    sx={{ color: "#A7BAD1" }}
                    size="small"
                  />
                </div>
                <div className="name"> Post-Test </div>
              </div>
            </div>
            <div className="seperator gray"></div>

            <div className={clsx(filterOptions.task === null && "disabled")}>
              <div className="filter-title">Study</div>
              <div className="filter-options">
                <div>
                  <Checkbox
                    onChange={(e) => {
                      setFilterOptions((curFilters) => {
                        if (e.target.checked) {
                          curFilters.study.add("VALIDATION_STUDY");
                        } else {
                          curFilters.study.delete("VALIDATION_STUDY");
                        }
                        return {
                          ...curFilters,
                        };
                      });
                    }}
                    disabled={filterOptions.task === null}
                    sx={{ color: "#A7BAD1" }}
                  />
                </div>
                <div className="name"> Validation </div>
                <Symbol letter={"V"} />
              </div>
              <div className="filter-options">
                <div>
                  <Checkbox
                    onChange={(e) => {
                      setFilterOptions((curFilters) => {
                        if (e.target.checked) {
                          curFilters.study.add("EXPERIMENT");
                        } else {
                          curFilters.study.delete("EXPERIMENT");
                        }
                        return {
                          ...curFilters,
                        };
                      });
                    }}
                    disabled={filterOptions.task === null}
                    sx={{ color: "#A7BAD1" }}
                  />
                </div>
                <div className="name am"> Experiment HP </div>
                <Symbol letter={"H"} />
              </div>
              <div className="filter-options">
                <div>
                  <Checkbox
                    onChange={(e) => {
                      setFilterOptions((curFilters) => {
                        if (e.target.checked) {
                          curFilters.study.add("EXPERIMENT_AM");
                        } else {
                          curFilters.study.delete("EXPERIMENT_AM");
                        }
                        return {
                          ...curFilters,
                        };
                      });
                    }}
                    disabled={filterOptions.task === null}
                    sx={{ color: "#A7BAD1" }}
                  />{" "}
                </div>
                <div className="name am"> Experiment AM </div>
                <Symbol letter={"A"} />
              </div>
              <div className="filter-options">
                <div>
                  <Checkbox
                    onChange={(e) => {
                      setFilterOptions((curFilters) => {
                        if (e.target.checked) {
                          curFilters.study.add("CONTROL");
                        } else {
                          curFilters.study.delete("CONTROL");
                        }
                        return {
                          ...curFilters,
                        };
                      });
                    }}
                    disabled={filterOptions.task === null}
                    sx={{ color: "#A7BAD1" }}
                  />{" "}
                </div>
                <div className="name"> Control </div>
                <Symbol letter={"C"} />
              </div>
            </div>
            <div className="seperator gray"></div>
            <div
              className={clsx(
                "select-all",
                filterOptions.task === null && "disabled",
              )}
            >
              <div className="filter-title">Completion</div>
              <div className="filter-options">
                <div>
                  <Checkbox
                    onChange={(e) => {
                      setFilterOptions((curFilters) => {
                        if (e.target.checked) {
                          curFilters.completion.add("notstarted");
                        } else {
                          curFilters.completion.delete("notstarted");
                        }
                        return {
                          ...curFilters,
                        };
                      });
                    }}
                    disabled={filterOptions.task === null}
                    sx={{ color: "#A7BAD1" }}
                  />{" "}
                </div>
                <div className="name"> Not Started </div>
              </div>
              <div className="filter-options">
                <div>
                  <Checkbox
                    onChange={(e) => {
                      setFilterOptions((curFilters) => {
                        if (e.target.checked) {
                          curFilters.completion.add("inprogress");
                        } else {
                          curFilters.completion.delete("inprogress");
                        }
                        return {
                          ...curFilters,
                        };
                      });
                    }}
                    disabled={filterOptions.task === null}
                    sx={{ color: "#A7BAD1" }}
                  />{" "}
                </div>
                <div className="name"> In Progress </div>
              </div>
              <div className="filter-options">
                <div>
                  <Checkbox
                    onChange={(e) => {
                      setFilterOptions((curFilters) => {
                        if (e.target.checked) {
                          curFilters.completion.add("completed");
                        } else {
                          curFilters.completion.delete("completed");
                        }
                        return {
                          ...curFilters,
                        };
                      });
                    }}
                    disabled={filterOptions.task === null}
                    sx={{ color: "#A7BAD1" }}
                  />{" "}
                </div>
                <div className="name"> Complete </div>
              </div>
            </div>
            <div className="seperator gray"></div>
          </div>
          <div className="users search-cont">
            <div className="title">
              <div>Search for users:</div>
            </div>
            <div style={{ width: "100%" }} className="seperator"></div>
            <div className="actions-section">
              <div className="flex-row">
                {/* <div
                  className={clsx(
                    "select-all",
                    filterOptions.task === null && "disabled",
                  )}
                >
                  <Checkbox
                    disabled={filterOptions.task == null}
                    checked={isSACheckedAll}
                    onChange={() => setIsSACheckedAll(!isSACheckedAll)}
                    sx={{ color: "#A7BAD1", padding: 0, marginRight: 0.5 }}
                  />
                  Select All Users
                </div> */}
                <div
                  className={clsx(
                    "select-all",
                    filterOptions.task === null && "disabled",
                  )}
                >
                  {/* <Checkbox
                    disabled={filterOptions.task == null}
                    checked={isSAChecked}
                    onChange={() => setIsSAChecked(!isSAChecked)}
                    sx={{ color: "#A7BAD1", padding: 0, marginRight: 0.5 }}
                  />
                  Select All from Page */}
                </div>
              </div>

              {/* <div
                className={clsx(
                  "submit-btn",
                  selectedUsers && selectedUsers.size === 0 && "disabled",
                )}
                onClick={() => {
                  if (selectedUsers && selectedUsers.size === 0) return;
                  setModalState({ action: "enablePostTest" });
                  setIsActionsOpen(true);
                }}
              >
                Enable
              </div> */}
            </div>

            {filterOptions.task === null ? (
              <div className="task_select_prompt">
                Please select a task in filters to view users
              </div>
            ) : (
              <div className="list">
                <div className="accordian-header">
                  <div> &nbsp; </div>
                  <div> Full Name </div>
                  <div style={{ marginLeft: "-3.5rem" }}> Participant ID </div>
                  <div style={{ marginLeft: "-3.5rem" }}> Current Study </div>
                  <div style={{ marginLeft: "-3.5rem" }}> Progress </div>
                  <div> &nbsp; </div>
                </div>
                {selectedUsers.size != 0 && (
                  <div>
                    <div id="selected-users">
                      <div className="title">Selected Users:</div>
                      {Array.from(selectedUsers).map((user, index) => {
                        user = user[1];
                        return (
                          <AccordianElement
                            key={index}
                            handleCheckbox={() =>
                              handleCheckbox(user.participantId, user)
                            }
                            checked={selectedUsers.has(user.participantId)}
                            progress={user.progress}
                            letter={user.letter}
                            firstName={user.firstName}
                            lastName={user.lastName}
                            id={user.participantId}
                            email={user.email}
                            preTestActivated={user.preTestActivated}
                            trainingsActivated={user.trainingsActivated}
                            postTestActivated={user.postTestActivated}
                            dateJoined={user.dateJoined}
                          />
                        );
                      })}
                    </div>
                  </div>
                )}
                {userData.map((user, index) => {
                  if (!selectedUsers.has(user.participantId))
                    return (
                      <AccordianElement
                        key={index}
                        handleCheckbox={() =>
                          handleCheckbox(user.participantId, user)
                        }
                        checked={selectedUsers.has(user.participantId)}
                        progress={user.progress}
                        letter={user.letter}
                        firstName={user.firstName}
                        lastName={user.lastName}
                        id={user.participantId}
                        email={user.email}
                        preTestActivated={user.preTestActivated}
                        trainingsActivated={user.trainingsActivated}
                        postTestActivated={user.postTestActivated}
                        dateJoined={user.dateJoined}
                      />
                    );
                })}
              </div>
            )}
          </div>
        </div>
        {filterOptions.task !== null && (
          <div
            className={clsx(
              "pagination-cont",
              filterOptions.task === null && "disabled",
            )}
          >
            <div className="flex-row">
              <div className="pagination-text">Rows per page:</div>
              <Select
                sx={{
                  color: "white",
                  svg: { color: "white" },
                  border: "2px solid white !important",
                  width: "4rem",
                }}
                disabled={filterOptions.task === null}
                labelId="users-page-select"
                id="page-select"
                value={no_of_users}
                label="Age"
                onChange={(e) => setNoOfUsers(e.target.value)}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </div>

            <Pagination
              disabled={filterOptions.task === null}
              shape="rounded"
              onChange={(event, value) => {
                setPage(value);
              }}
              page={page}
              color="primary"
              count={totalPages}
            />
          </div>
        )}
        <ProfileModal
          openState={[isProfileOpen, setIsProfileOpen]}
          userInfo={ResearcherUserInfo}
        />
      </div>
      {isActionsOpen && (
        <ResearchModal
          openState={[isActionsOpen, setIsActionsOpen]}
          modalState={modalState}
        />
      )}
    </OverlayScrollbarsComponent>
  );
}
