import {
  IconDownload,
  IconExclamationCircle,
  IconFile,
  IconFileExcel,
  IconFileUpload,
  IconRosetteDiscountCheck,
} from "@tabler/icons-react";
import axios from "axios";
import { useRef, useState } from "react";
import { ServerUrl, xlsxToJson } from "../../Utils";
import ProfileModal from "../../components/ProfileModal";
import ProfilePhoto from "../../components/ProfilePhoto";
import "../../styles/research-upload.css";

export default function Upload() {
  document.body.id = "research-upload";

  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);
  const [uploadResult, setUploadResult] = useState({
    success: [],
    errors: [],
  });
  const [successfullUpload, setSuccessfullUpload] = useState(false);
  const inputRef = useRef(null);

  const ResearcherUserInfo = {
    firstName: "Andrew",
    lastName: "Hellinger",
    email: "andrew@jhu.edu",
    role: "RESEARCHER",
  };

  function triggerUpload() {
    if (inputRef) {
      inputRef.current.click();
    }
    return 0;
  }
  function handleFileUpload(e) {
    setUploadFile(e.target.files[0]);
    if (e.target.files[0].size > 1024 * 1024 * 50) {
      alert("File size should be less than 50mb");
    }
  }

  function updateResponse(feedbacks) {
    const updatedResponse = {
      errors: [],
      success: [],
    };
    feedbacks.map((feedback) => {
      if (feedback.isError) {
        updatedResponse.errors.push(feedback.message);
      } else {
        updatedResponse.success.push(feedback.message);
      }
      setUploadResult(updatedResponse);
    });
  }

  function upload() {
    xlsxToJson(uploadFile).then(async (data) => {
      await axios
        .post(
          `${ServerUrl}/users/upload`,
          {
            data,
          },
          {
            withCredentials: true,
          },
        )
        .then(async (res) => {
          updateResponse(res.data.data);
          setSuccessfullUpload(true);
        })
        .catch((err) => {
          const feedback = err.response.data.data;
          updateResponse(feedback);
          setSuccessfullUpload(true);
        });
    });
  }

  function resetFileUpload() {
    setUploadFile(null);
  }

  return (
    <div>
      <div className="header">
        <div className="flex-row">
          <ProfilePhoto
            source="https://www.w3schools.com/howto/img_avatar.png"
            onClick={() => setIsProfileOpen(true)}
          />
          <header>Welcome, {ResearcherUserInfo.firstName}! </header>
        </div>
        <div className="flex-row">
          <div>Upload Users</div>
          <div className="flex-row">Search</div>
        </div>
      </div>

      <main className="researcher-upload">
        <form className="user-file-upload" onClick={triggerUpload}>
          {!uploadFile ? (
            <>
              <input
                type="file"
                accept=".xlsx"
                ref={inputRef}
                onChange={handleFileUpload}
                hidden
              />
              <IconFileUpload size={64} color="white" />
              <div className="upload-instrctions">
                <div className="title">Upload Users for Hazard Perception</div>
                <span>
                  The excel file <b>should</b> follow a specific format. See
                  template below for reference
                </span>
                <span>Accepted file formats: .XLSX</span>
                <span>File Size Limit: 50 MB</span>
              </div>
            </>
          ) : (
            <>
              <IconFileUpload size={64} color="white" />
              <div className="upload-instrctions">
                <div className="title"> {uploadFile.name} </div>
                <span>
                  {successfullUpload ? (
                    <>File uploaded successfully</>
                  ) : (
                    <>File is ready to upload</>
                  )}{" "}
                </span>
              </div>
            </>
          )}
        </form>
        {uploadFile && (
          <div className="upload-options">
            <div className="button" onClick={upload}>
              Upload <IconFileUpload />
            </div>
            <div className="button" onClick={resetFileUpload}>
              Clear File <IconFileExcel />
            </div>
          </div>
        )}
        {uploadResult && successfullUpload && (
          <div className="response">
            <div className="feedback errors">
              {uploadResult.errors.length == 0 ? (
                <>
                  <div className="title"> No errors found!</div>
                </>
              ) : (
                <>
                  <div className="title"> Errors while uploading: </div>
                  <ul>
                    {uploadResult["errors"].map((error, idx) => {
                      return (
                        <li key={idx}>
                          <>{error}</>
                          <IconExclamationCircle size={32} />
                        </li>
                      );
                    })}
                  </ul>
                </>
              )}
            </div>
            <div className="feedback success">
              {uploadResult.success.length == 0 ? (
                <>
                  <div className="title"> No successful additions</div>
                </>
              ) : (
                <>
                  <div className="title">Users Uploaded Successfully: </div>
                  <ul>
                    {uploadResult["success"].map((success, idx) => {
                      return (
                        <li key={idx}>
                          <>{success}</>
                          <IconRosetteDiscountCheck size={32} />
                        </li>
                      );
                    })}
                  </ul>
                </>
              )}
            </div>
          </div>
        )}
        <div className="upload-template">
          <div className="title">Template File:</div>
          <div className="template-row">
            <div className="template-row">
              <IconFile size={48} color="white" /> User Upload.xlsx
            </div>
            <a className="button" href="./Upload_Users.xlsx" download>
              Download <IconDownload />
            </a>
          </div>
        </div>
        {/* {successfullUpload && <div>Users have been uploaded successfully!</div>} */}
      </main>
      <ProfileModal
        openState={[isProfileOpen, setIsProfileOpen]}
        userInfo={ResearcherUserInfo}
      />
    </div>
  );
}
