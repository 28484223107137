import { IconMail } from "@tabler/icons-react";
import axios from "axios";
import clsx from "clsx";
import forge from "node-forge";
import { createRef, useContext, useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import {
  ServerUrl,
  copy,
  defaults,
  encryptWithPublicKey,
  getSubset,
  pause,
} from "../Utils";
import { ModalContext } from "../contexts/Contexts";
import { Button, setLoading, shake, stopLoading } from "./Button";
import InputField, { InputHeader } from "./InputField";
import Modal, { ScrollToError, focusFirstField, swapContent } from "./Modal";

/** @param {{ state: UseState<boolean> }} */
const RegisterModal = ({ state }) => {
  let captcha = ReCAPTCHA; // prevent import removal
  const recaptchaRef = createRef();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = state;

  // personal info is read-only,
  // account info is input by user
  const defaultPersonalState = copy(defaults.register.personal);
  const defaultAccountState = copy(defaults.register.account);
  const defaultState = { ...defaultPersonalState, ...defaultAccountState };
  const [personalInfo, setPersonalInfo] = useState(defaultPersonalState);
  const [accountInfo, setAccountInfo] = useState(defaultAccountState);
  // error states for account info
  const [formErr, setFormErr] = useState(defaultState);
  const [encodedToken, setEncodedToken] = useState(null);

  const mailToContact = "mailto: support@hazardperception.org";

  useEffect(() => {
    if (!isOpen) return;
    const urlParams = new URLSearchParams(window.location.search);
    let token = urlParams.get("token");
    if (token) {
      try {
        token = atob(token);
        setEncodedToken(token);
        setPersonalInfo({
          ...personalInfo,
          participantId: JSON.parse(token).participantId,
        });
      } catch (err) {
        setEncodedToken("");
        return;
      }
    }
  }, [isOpen]);

  async function verify(event, modalContent) {
    const btn = event.target;
    if (!accountInfo.drivingLicenseId) {
      setFormErr({
        ...formErr,
        drivingLicenseId: "Required field missing",
      });
      shake(btn);
      return;
    }
    setLoading(btn);
    await axios
      .post(`${ServerUrl}/users/${personalInfo.participantId}/verify-license`, {
        drivingLicenseId: accountInfo.drivingLicenseId,
      })
      .then((res) => {
        const user = res.data.data;
        swapContent(modalContent, async () => {
          await pause(150);
          setFormErr(defaultState);
          setPersonalInfo({
            ...personalInfo,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
          });
          await pause(150);
        });
        stopLoading(btn);
        pause(650).then(() => focusFirstField(modalContent));
      })
      .catch((err) => {
        console.log(err);
        const error = err.response.data;
        setFormErr({
          ...defaultState,
          [error.data]: error.message,
        });
        setPersonalInfo({
          ...defaultPersonalState,
          participantId: personalInfo.participantId,
        });
        stopLoading(btn);
        shake(btn);
      });
  }

  function formInput(event) {
    const input = event.target;
    if (input) {
      setAccountInfo({
        ...accountInfo,
        [input.id]: input.value.trim(),
      });
    }
  }

  async function handleRegister(event) {
    let validForm = true;
    let errors = defaultState;
    const btn = event.target;
    let user = {};
    for (let field in { ...accountInfo, ...personalInfo }) {
      if (!accountInfo[field] && !personalInfo[field]) {
        errors[field] = "Required field missing";
        validForm = false;
      }
    }
    setFormErr(errors);
    if (!validForm) {
      stopLoading(btn);
      shake(btn);
      return;
    }

    setLoading(btn);
    await axios
      .post(
        `${ServerUrl}/users/register`,
        {
          ...personalInfo,
          drivingLicenseId: accountInfo.drivingLicenseId,
          password: encryptWithPublicKey(accountInfo.password),
          confirmPassword: encryptWithPublicKey(accountInfo.confirmPassword),
          dateJoined: new Date().toISOString(),
        },
        {
          withCredentials: true,
        },
      )
      .then((res) => {
        user = res.data.data;

        const md = forge.md.sha256.create();
        md.update(user.email);

        closeModal();
        navigate("/dashboard", {
          state: {
            ...getSubset(user, defaults.dashboardState),
            emailHash: md.digest().toHex(),
          },
        });
      })
      .catch((err) => {
        console.log(err);
        if (err.code === "ERR_NETWORK") return;
        const res = err.response;
        setFormErr({
          ...defaultState,
          [res.data.data]: res.data.message,
        });
        stopLoading(btn);
        shake(btn);
      });
  }

  function handleCancel(event) {
    event.preventDefault();
    setFormErr(defaultState);
    closeModal();
  }

  async function closeModal() {
    await pause(100);
    setIsOpen(false);
  }

  const onReCAPTCHASubmit = () => {
    const token = recaptchaRef.current.getValue();
  };

  const contactLink = (
    <a href={mailToContact} style={{ padding: "0 0.4rem" }}>
      {<IconMail />} Contact Us
    </a>
  );

  return (
    <Modal
      id="register-modal"
      className="register-modal"
      title="New Account"
      contentClassName={clsx("register-form", personalInfo.email && "full")}
      contentLabel="Register Modal"
      isOpen={isOpen}
      close={handleCancel}
      afterClose={() => setFormErr(defaultState)}
      customWidth={!personalInfo.email && "30rem"}
      focusOnOpen
    >
      <Helmet>
        <title>Register | Hazard Perception</title>
      </Helmet>
      <ScrollToError deps={[formErr]} />
      {personalInfo.email ? (
        <RegisterForm
          onFormInput={formInput}
          onRegisterClick={handleRegister}
          personalInfo={personalInfo}
          accountInfo={accountInfo}
          formErr={formErr}
          contactLink={contactLink}
        />
      ) : encodedToken ? (
        <RegisterVerifyForm
          onVerifyClick={verify}
          onFormInput={formInput}
          defaultState={defaultState}
          accountInfo={accountInfo}
          personalInfo={personalInfo}
          formErr={formErr}
          contactLink={contactLink}
        />
      ) : (
        <>
          <header className="message">
            You need a valid invitation to register. Please reach out to the
            study team for more information.
          </header>
          <Button
            type="submit"
            label="Contact Study Team"
            onClick={() => {
              location.href = mailToContact;
              closeModal();
            }}
            styles={{
              marginBottom: "1rem",
            }}
          />
        </>
      )}
      {/* <ReCAPTCHA
        ref={recaptchaRef}
        sitekey="6LeVQpQmAAAAALR80Je8if_SYDqY0h1ZYEV8jwMk"
        onChange={onReCAPTCHASubmit}
      /> */}
    </Modal>
  );
};

/**
 * @typedef {(
 *    Defaults["register"]["account"] &
 *    Defaults["register"]["personal"]
 * )} DefaultFormState
 * @param {{
 *    onFormInput: function,
 *    onVerifyClick: function,
 *    defaultState: DefaultFormState,
 *    accountInfo: Defaults["register"]["account"],
 *    personalInfo: Defaults["register"]["personal"],
 *    formErr: DefaultFormState,
 *    contactLink: JSX.Element,
 * }} props
 */
const RegisterVerifyForm = (props) => {
  const { onVerifyClick, onFormInput, defaultState, ...formState } = props;
  const { accountInfo, personalInfo, formErr } = formState;
  const { contentElement } = useContext(ModalContext);
  const lockedPlaceholder = "Not Available";

  return (
    <>
      <div className="input-group">
        <div className="input-col">
          <header className="message">
            Please verify your Activation ID to continue to registration.
          </header>
          <InputField
            id="participantId"
            label="Participant ID"
            placeholder={lockedPlaceholder}
            value={personalInfo.participantId}
            error={formErr.participantId}
            disabled
          />
          <InputField
            id="drivingLicenseId"
            label="Activation ID"
            value={accountInfo.drivingLicenseId}
            autoComplete="off"
            onChange={onFormInput}
            error={formErr.drivingLicenseId}
            hideValue
          />
          <div
            className="discrepancy-link"
            style={{ transform: "translateY(-0.4rem)" }}
          >
            <header>Not Your Info?</header>
            {props.contactLink}
          </div>
        </div>
      </div>
      <Button
        type="submit"
        label="Verify Activation ID"
        onClick={(event) => onVerifyClick(event, contentElement)}
        styles={{ marginBottom: "1rem" }}
      />
    </>
  );
};

/**
 *
 * @param {{
 *    onFormInput: function,
 *    onRegisterClick: function,
 *    personalInfo: Defaults["register"]["personal"],
 *    accountInfo: Defaults["register"]["account"],
 *    formErr: DefaultFormState,
 *    contactLink: JSX.Element,
 * }} props
 */
const RegisterForm = (props) => {
  const { onFormInput, onRegisterClick, ...formState } = props;
  const { personalInfo, accountInfo, formErr } = formState;
  const lockedPlaceholder = "Not Available";

  return (
    <>
      <div className="input-group">
        <div className="input-col">
          <InputHeader label="Account Info" />
          <InputField
            id="participantId"
            label="Participant ID"
            placeholder={lockedPlaceholder}
            value={personalInfo.participantId}
            error={formErr.participantId}
            disabled
          />
          <InputField
            type="password"
            id="password"
            label="Password"
            value={accountInfo.password}
            autoComplete="new-password"
            onChange={onFormInput}
            error={formErr.password}
          />
          <InputField
            type="password"
            id="confirmPassword"
            label="Confirm Password"
            value={accountInfo.confirmPassword}
            autoComplete="new-password"
            onChange={onFormInput}
            error={formErr.confirmPassword}
          />
        </div>
        <div className="input-col">
          <InputHeader label="Personal Info" />
          <InputField
            id="firstName"
            label="First Name"
            placeholder={lockedPlaceholder}
            value={personalInfo.firstName}
            error={formErr.firstName}
            disabled
          />
          <InputField
            id="lastName"
            label="Last Name"
            placeholder={lockedPlaceholder}
            value={personalInfo.lastName}
            error={formErr.lastName}
            disabled
          />
          <InputField
            id="email"
            label="Email"
            placeholder={lockedPlaceholder}
            value={personalInfo.email}
            error={formErr.email}
            disabled
          />
        </div>
      </div>
      <div
        className="discrepancy-link"
        style={{ transform: "translateY(-1rem)" }}
      >
        <header>Found A Mistake?</header>
        {props.contactLink}
      </div>
      <Button
        type="submit"
        label="Create Account"
        onClick={onRegisterClick}
        styles={{
          marginBottom: "1rem",
        }}
      />
    </>
  );
};

export default RegisterModal;
