import "overlayscrollbars/overlayscrollbars.css";
import { useState } from "react";
import { HelmetProvider } from "react-helmet-async";
import { Navigate, Route, Routes } from "react-router-dom";
import { BannerContext } from "../Utils";
import Banner from "../components/Banner";
import DisplayInfoProvider from "../contexts/DisplayInfo";
import ScenarioInfoProvider from "../contexts/ScenarioInfo";
import Dashboard from "../pages/Dashboard";
import Home from "../pages/Home";
import PageNotFound from "../pages/PageNotFound";
import ResetPassword from "../pages/ResetPassword";
import Scenario from "../pages/Scenario";
import StudyConcluded from "../pages/StudyConcluded";
import Testing from "../pages/Testing";
import Training from "../pages/Training";
import Unauthorized from "../pages/Unauthorized";
import ResearcherDashboard from "../pages/research/Dashboard";
import ResearcherSearch from "../pages/research/Search";
import ResearcherUpload from "../pages/research/Upload";
import "../styles/animations.css";
import "../styles/colors.css";
import "../styles/layout.css";
import "../styles/scrollbar.css";
import "../styles/text.css";
import ProtectedRoute from "./ProtectedRoute";

function Container() {
  const bannerState = useState({ icon: null, text: "" });

  document.body.classList.add("font-rubik"); // font to be used

  return (
    <DisplayInfoProvider>
      <BannerContext.Provider value={bannerState}>
        <HelmetProvider>
          <Banner />
          <Routes>
            <Route path="/" element={<Navigate to="/home" />} />
            <Route path="/home" element={<Home />} />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/scenario"
              element={
                <ProtectedRoute>
                  <ScenarioInfoProvider>
                    <Scenario />
                  </ScenarioInfoProvider>
                </ProtectedRoute>
              }
            />
            <Route
              path="/testing"
              element={
                <ProtectedRoute>
                  <Testing />
                </ProtectedRoute>
              }
            />
            <Route
              path="/training"
              element={
                <ProtectedRoute>
                  <Training />
                </ProtectedRoute>
              }
            />
            <Route
              path="/research/dashboard"
              element={
                <ProtectedRoute>
                  <ResearcherDashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/research/search"
              element={
                <ProtectedRoute>
                  <ResearcherSearch />
                </ProtectedRoute>
              }
            />
            <Route
              path="/research/upload"
              element={
                <ProtectedRoute>
                  <ResearcherUpload />
                </ProtectedRoute>
              }
            />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/unauthorized" element={<Unauthorized />} />
            <Route path="/study-concluded" element={<StudyConcluded />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </HelmetProvider>
      </BannerContext.Provider>
    </DisplayInfoProvider>
  );
}

export default Container;
