import ProgressBar from "@ramonak/react-progress-bar";
import { IconCheck, IconX } from "@tabler/icons-react";
import { useSingleton } from "@tippyjs/react";
import chroma from "chroma-js";
import clsx from "clsx";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import { getCssVar, pause } from "../Utils";
import "../styles/progress.css";
import Tooltip from "./Tooltip";

/** @type {ForwardRef<SectionProgressProps, HTMLDivElement>} */
export const SectionedProgressBar = forwardRef((props, ref) => {
  // prettier-ignore
  const {
    id,
    className,
    data,
    hideSections,
    height,
    styles,
    detachTooltips,
  } = props;

  // parse sections to render
  const parsedData = [];
  let intPercent = 0.5;
  let parsedHideSections = false;
  const [wideSections, setWideSections] = useState(false);
  const sizeObserver = new ResizeObserver(checkWidth);
  const [srcTooltip, targetTooltip] = useSingleton({
    overrides: ["placement", "theme"],
  });

  if (typeof data === "number" && hideSections) {
    parsedData.push(...["correct", "incomplete"]);
    intPercent = data;
    parsedHideSections = true;
  } else if (data instanceof Array) {
    parsedData.push(...data);
  }

  function checkWidth(entries) {
    const width = entries[0].contentRect.width;
    if (width > 0) setWideSections(width > 50);
  }

  function populateSections() {
    const sectionElements = [];

    for (let i = 0; i < parsedData.length; i++) {
      const incorrect = parsedData[i] === "incorrect";
      const state = parsedData[i] || "incomplete";

      sectionElements.push(
        <SectionElement
          key={`block-${i}`}
          incorrect={incorrect}
          state={state}
          wide={wideSections}
          observer={!i && sizeObserver}
          hideTooltip={parsedHideSections}
          detachTooltip={detachTooltips}
          singleton={state !== "incomplete" ? targetTooltip : undefined}
        />,
      );
    }

    return sectionElements;
  }

  return (
    <div
      id={id}
      className={clsx(
        "section-progress",
        className,
        parsedHideSections && "hide-sections",
        intPercent <= 0 && "no-progress",
        intPercent >= 100 && "full-progress",
      )}
      num-sections={parsedData.length}
      role="progressbar"
      style={{
        ...styles,
        ...(intPercent > 0
          ? {
              "--percent": `${intPercent} / 100`,
            }
          : {}),
        "--section-progress-height": height,
      }}
      ref={ref}
    >
      <Tooltip
        className="section-tooltip"
        singleton={srcTooltip}
        disabled={parsedHideSections}
        moveTransition="transform 150ms ease"
        delay={[350, 300]}
      />
      {populateSections()}
    </div>
  );
});

/**
 *
 * @param {{
 *    wide: boolean,
 *    incorrect: boolean,
 *    observer: ResizeObserver,
 *    singleton: SingletonObject,
 *    hideTooltip: boolean,
 *    detachTooltip: boolean,
 *    state: string
 * }} props
 * @returns {React.JSX.Element}
 */
function SectionElement(props) {
  const {
    observer,
    wide,
    incorrect,
    state,
    detachTooltip,
    hideTooltip,
    singleton,
  } = props;

  const [ref, setRef] = useState(null);

  useEffect(() => {
    if (observer && ref) observer.observe(ref);
  }, [observer, ref]);

  const section = (
    <span className={clsx("section", `block-${state}`)} ref={setRef}>
      {incorrect && wide && <IconX className="incorrect" stroke={3} />}
    </span>
  );

  if (detachTooltip) return section;
  return (
    <Tooltip
      theme={incorrect && "incorrect"}
      singleton={hideTooltip ? undefined : singleton}
      content={
        <>
          {incorrect && !wide && <IconX stroke={3} />}
          {!incorrect && !wide && <IconCheck stroke={3} />}
          {state && state[0].toUpperCase() + state.slice(1)}
        </>
      }
      disabled={state === "incomplete" || hideTooltip}
    >
      {section}
    </Tooltip>
  );
}

/** @param {ContinuousProgressProps} props */
export function ContinuousProgressBar(props) {
  const { className, percentage, initPercent, initAnimate } = props;

  const empty = percentage === 0 ? "empty" : "";
  const loading = !percentage && percentage !== 0;
  const loadingTag = loading ? "loading" : "";
  const tags = `${empty} ${loadingTag}`;

  return (
    <ProgressBar
      completed={percentage || 0}
      className={clsx("linear-progress", className, tags)}
      initCompletedOnAnimation={initPercent}
      animateOnRender={initAnimate}
      isLabelVisible={false}
    />
  );
}

/** @param {CircularProgressProps} props */
export const CircularProgressBar = (props) => {
  const {
    id,
    className,
    width,
    stroke,
    percentage,
    variant = "light",
    fgColor,
    bgColor,
    tint,
    hideSymbol,
    counterClockwise,
    circleRatio = 1,
    isTest,
    correctAnswers,
    numActivitiesTotal,
    styles,
  } = props;

  const emptyScore = numActivitiesTotal === 0;
  const scoreFrac = correctAnswers / numActivitiesTotal;
  const completionProgress = Math.max(0, Math.min(percentage, 100));
  const scoreProgress = correctAnswers ? Math.floor(scoreFrac * 100) : 0;

  const completionNaN = !isTest && isNaN(completionProgress);
  const scoreNaN = isTest && typeof correctAnswers !== "number";
  const loading = completionNaN || scoreNaN;

  const loadingTag = loading ? "loading" : "";
  const classTags = [variant, loadingTag];
  const [value, setValue] = useState(loading ? 15 : 0);
  const [labelVal, setLabelVal] = useState(-1);
  const [transition, setTransition] = useState("");
  const largeScore = correctAnswers < 10 && numActivitiesTotal < 10;

  const [parsedFgColor, setFgColor] = useState(fgColor);
  const [parsedBgColor, setBgColor] = useState(bgColor);
  const [currTint, setCurrTint] = useState("");
  const circleRef = useRef(null);

  useEffect(() => {
    if (loading) {
      renderLoad();
    } else {
      renderInit();
    }
  }, [scoreProgress, completionProgress]);

  useEffect(() => {
    if (!circleRef.current) return;
    if (currTint === tint) return;
    tintCircle();
  }, [tint]);

  async function renderInit() {
    if (transition === "none") {
      setValue(0);
      await pause(500);
    }
    setTransition("500ms ease");
    setLabelVal(completionNaN ? -1 : formatPercent(completionProgress));
    setValue(isTest ? scoreProgress : completionProgress);
    await pause(500);
    setTransition("stroke 150ms ease");
  }

  async function renderLoad() {
    if (value !== 15) {
      setValue(15);
    }
    await pause(500);
    setTransition("stroke 150ms ease");
  }

  function formatPercent(num) {
    if (num < 10) return Math.round(num * 10) / 10;
    if (num < 100) return Math.round(num);
    return num;
  }

  function calcLabelShift() {
    const shift = 5 + 400 * (circleRatio - 1) ** 4;
    return -Math.min(30, Math.round(shift));
  }

  function calcMarginRatio() {
    const sine = Math.sin(Math.PI * (circleRatio - 0.5));
    return Math.max(0.5, (1 + sine) / 2);
  }

  function tintCircle() {
    setCurrTint(tint);
    const bgColor = getCssVar(circleRef.current, "--main-bg-color");

    let parsedTint = tint || "";
    if (parsedTint.startsWith("var(") || parsedTint.endsWith(")")) {
      const tintVar = /var\((.*)\)/g.exec(parsedTint)[1];
      parsedTint = getCssVar(circleRef.current, tintVar);
    }
    if (!chroma.valid(parsedTint) || !chroma.valid(bgColor)) return;

    const chromaTint = chroma(parsedTint).saturate(0.4).darken(0.4);
    const tinted = chroma.mix(chromaTint, chroma(bgColor), 0.4).hex();
    if (variant === "light") {
      setFgColor(fgColor);
      setBgColor(tinted);
    } else {
      setFgColor(tinted);
      setBgColor(bgColor);
    }
  }

  return (
    <div
      id={id}
      className={clsx(
        "circular-progress",
        circleRatio < 1 && "arc",
        className,
        classTags,
      )}
      style={{
        ...styles,
        "--circular-progress-fg": parsedFgColor,
        "--circular-progress-bg": parsedBgColor,
        "--circ-max-width": width,
        "--circ-stroke-width": stroke,
        "--circle-ratio": circleRatio,
        "--label-shift": `${calcLabelShift()}%`,
        "--margin-ratio": calcMarginRatio(),
      }}
      ref={circleRef}
    >
      <CircularProgressbar
        classes={{
          root: "progress-root",
          trail: "circle-trail",
          path: "circle-path",
        }}
        value={value}
        role="progressbar"
        circleRatio={circleRatio}
        strokeWidth={stroke || 12}
        counterClockwise={counterClockwise}
        styles={{
          root: {
            transition: "stroke-dashoffset 500ms ease",
          },
          path: {
            stroke: "var(--circular-progress-fg)",
            transition: transition,
            transformOrigin: "center center",
          },
          trail: {
            stroke: "var(--circular-progress-bg)",
            transformOrigin: "center center",
          },
        }}
      />

      <span className="progress-label">
        {isTest && !scoreNaN ? (
          <span className={clsx("score", emptyScore && "empty")}>
            <span className={clsx("num", largeScore && "large")}>
              {correctAnswers}
            </span>
            <span className="bar">{emptyScore ? "-/-" : "/"}</span>
            <span className={clsx("denom", largeScore && "large")}>
              {numActivitiesTotal}
            </span>
          </span>
        ) : (
          <>
            <span className="completion">
              {loading || labelVal < 0 ? "--" : labelVal}
            </span>
            {!hideSymbol && !loading && labelVal >= 0 && (
              <span className="percent">{!isTest && "%"}</span>
            )}
          </>
        )}
      </span>
    </div>
  );
};
