import { pause } from "@src/Utils";
import Tippy from "@tippyjs/react";
import clsx from "clsx";
import { forwardRef } from "react";
import "../styles/tooltips.css";

/**
 * @typedef {import("@tippyjs/react").TippyProps} TippyProps
 * @type {ForwardRef<TippyProps, Element>}
 */
const Tooltip = forwardRef((props, ref) => {
  const {
    onShow,
    onHide,
    className,
    delay,
    duration,
    children,
    ...otherProps
  } = props;

  /** @param {TippyInstance} instance */
  function handleShow(instance) {
    animateTooltip(instance);
    onShow?.(instance);
  }

  /** @param {TippyInstance} instance */
  function handleHide(instance) {
    animateTooltip(instance);
    onHide?.(instance);
  }

  return (
    <Tippy
      className={clsx("tooltip", className)}
      onShow={handleShow}
      onHide={handleHide}
      delay={delay || [0, 250]}
      duration={duration || 150}
      ref={ref}
      {...otherProps}
    >
      {children}
    </Tippy>
  );
});

/**
 * @param {TippyInstance} instance
 * @param {number} hideAfterMS
 */
export async function animateTooltip(instance, hideAfterMS = 0) {
  const element = instance.popper.children[0];
  const opacity = instance.state.isVisible ? 0 : 1;
  if (!(element && element.style)) return;
  await pause(10);
  element.style.setProperty("--tt-opacity", opacity);
  if (hideAfterMS) {
    return setTimeout(() => {
      element.style.setProperty("--tt-opacity", 0);
    }, hideAfterMS);
  }
}

export default Tooltip;
