import { IconUserFilled } from "@tabler/icons-react";
import { forwardRef } from "react";
import { keyEnter } from "../Utils";

/** @type {ForwardRef<ProfilePhotoProps, HTMLButtonElement>} */
const ProfilePhoto = forwardRef((props, ref) => {
  const { onClick, style } = props;

  function handleClick(event) {
    event.preventDefault();
    onClick?.(event);
  }

  return (
    <button
      className="pfp"
      onClick={handleClick}
      onKeyDown={(event) => keyEnter(event, onClick)}
      style={style}
      disabled={!onClick}
      tabIndex={0}
      ref={ref}
    >
      <IconUserFilled aria-label="Profile Photo" />
    </button>
  );
});

export default ProfilePhoto;
