import { IconCheck, IconLock } from "@tabler/icons-react";
import chroma from "chroma-js";
import clsx from "clsx";
import { forwardRef, useImperativeHandle, useRef } from "react";
import { keyEnter } from "../Utils";

/** @type {ForwardRef<CardProps, HTMLDivElement>} */
const Card = forwardRef((props, ref) => {
  const {
    children,
    id,
    className,
    tabIndex,
    onClick,
    draggable,
    onDrag,
    onDragStart,
    onDragEnd,
    onMouseOver,
    onMouseOut,
    bgColor,
    width,
    height,
    disabled,
    hideLock,
    showCheck,
    animateUnlock,
    style,
    ...otherProps
  } = props;

  const cardRef = useRef(null);
  const disabledTag = disabled ? "disabled" : "";

  // prettier-ignore
  useImperativeHandle(ref, () => {
    return cardRef.current;
  }, []);

  function init(card) {
    cardRef.current = card;
    const style = window.getComputedStyle(card);
    let color1 = bgColor || "";
    const color2 = style.getPropertyValue("--disabled-card-bg-color");

    if (color1.startsWith("var(") || color1.endsWith(")")) {
      const colorVar = /var\((.*)\)/g.exec(color1)[1];
      color1 = style.getPropertyValue(colorVar);
    }
    if (!chroma.valid(color2) || !chroma.valid(color1)) return;

    const tintedMix = chroma.mix(chroma(color1), chroma(color2), 0.6);
    card.style.setProperty(
      "--tinted-card-bg-color",
      tintedMix.darken(0.6).hex(),
    );
  }

  function showDisabledBanner() {
    // TODO
  }

  return (
    <div
      id={id}
      className={clsx("card", className, disabledTag)}
      tabIndex={tabIndex}
      draggable={draggable}
      onDrag={onDrag}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      onClick={disabled ? null : (event) => onClick?.(event)}
      onKeyDown={(event) =>
        keyEnter(event, (event) => {
          const btn = event.target.querySelector(".btn");
          if (btn) btn.click(event);
          else if (!disabled) {
            onClick?.(event);
          }
        })
      }
      style={{
        "--default-card-bg-color": bgColor,
        width,
        height,
        ...style,
      }}
      ref={(card) => card && init(card)}
      {...otherProps}
    >
      {!hideLock &&
        (disabled || animateUnlock) &&
        (showCheck ? (
          <IconCheck
            className="complete-check"
            color="var(--main-text-color)"
            stroke={3}
            size="1.8em"
          />
        ) : (
          <IconLock
            className={clsx("disabled-lock", animateUnlock && "animate-unlock")}
            color="var(--main-text-color)"
            stroke={2.5}
            size="1.7em"
          />
        ))}
      {children}
    </div>
  );
});

export default Card;
