import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Checkbox from "@mui/material/Checkbox";
import { IconArrowDown } from "@tabler/icons-react";
import { clsx } from "clsx";
import { useState } from "react";
import "../styles/research-search.css";
import Symbol from "./ResearcherSymbol";

export default function AccordianElement(props) {
  const [expanded, setExpanded] = useState(false);
  function updateExpanded(event) {
    if (event.target.type === "checkbox") {
      event.stopPropagation();
    } else {
      setExpanded(!expanded);
    }
  }

  return (
    <Accordion
      expanded={expanded}
      onChange={(event) => updateExpanded(event)}
      className="accordian-elem"
      sx={{
        gap: "1rem",
        backgroundColor: "var(--soft-blue)",
        margin: "0.6rem 0",
        padding: "0 1rem",
        fontSize: "var(--font-size-s-md)",
        borderRadius: "6px !important",
      }}
    >
      <AccordionSummary
        sx={{
          padding: "0 1rem 0 0 !important",
        }}
        expandIcon={<IconArrowDown />}
      >
        <div className="accordian">
          <div>
            <Checkbox
              checked={props.checked}
              onClick={() => {
                props.handleCheckbox();
              }}
              sx={{ color: "#213760" }}
            />
          </div>
          <div>
            {props.firstName} {props.lastName}
          </div>
          <div>{props.id}</div>
          <Symbol letter={props.letter} stroke={true} />
          <div> {props.progress}% </div>
        </div>
        <div
          className={clsx(
            "seperator",
            "accordian-sep",
            expanded && "appear-fade-in",
          )}
        ></div>
      </AccordionSummary>
      <AccordionDetails>
        <div className="accordian-dets">
          <div className="text-details">
            {/* <div>
              Study:{" "}
              <span className="bold">
                {props.letter === "V"
                  ? "Validation"
                  : props.letter === "C"
                  ? "Control"
                  : props.letter === "E"
                  ? "Experiment"
                  : null}
              </span>
            </div> */}
            <div>
              Email: <span className="bold">{props.email}</span>{" "}
            </div>
            <div>
              Date Joined:
              <span className="bold">
                {props.dateJoined.split("T")[0]}{" "}
              </span>{" "}
            </div>
            <div>
              Last Activity: <span className="bold"> Module 2 Scenario 1 </span>{" "}
            </div>
            <div>
              Pre-Test Status:{" "}
              <span className="bold">
                {" "}
                {props.preTestActivated ? "Activated" : "Not Activated"}{" "}
              </span>{" "}
            </div>
            <div>
              Training Status:{" "}
              <span className="bold">
                {" "}
                {props.trainingsActivated ? "Activated" : "Not Activated"}{" "}
              </span>{" "}
            </div>

            <div>
              Post-Test Status:{" "}
              <span className="bold">
                {" "}
                {props.postTestActivated ? "Activated" : "Not Activated"}{" "}
              </span>{" "}
            </div>
          </div>
          {/* <div className="btn-cont">
            <span className="bold">Actions:</span>
            <Button
              reverse
              className="qa-btn"
              label={"Download CSV"}
              variant="dark"
              onClick={() => continueModule()}
            />
            <Button
              reverse
              className="qa-btn"
              label={"Send Reminder"}
              variant="dark"
              onClick={() => continueModule()}
            />
          </div> */}
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
