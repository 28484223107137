import { IconX } from "@tabler/icons-react";
import clsx from "clsx";
import { ClickScrollPlugin, OverlayScrollbars } from "overlayscrollbars";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { forwardRef, useContext, useEffect, useState } from "react";
import ReactModal from "react-modal";
import zenScroll from "zenscroll";
import { keyEnter, pause } from "../Utils";
import { ModalContext } from "../contexts/Contexts";
import ModalContextProvider from "../contexts/ModalContext";
import "../styles/modal.css";

/** @type {ForwardRef<ModalProps, ReactModal>} */
const Modal = forwardRef((props, ref) => {
  const {
    focusOnOpen,
    afterOpen,
    id,
    isOpen,
    close,
    afterClose,
    contentLabel,
    ariaHideApp,
    overlayStyles,
    contentStyles,
    customWidth,
    className,
    hideHeader,
    title,
    hideCloseBtn,
    contentClassName,
    children,
    scrollElement,
  } = props;

  const [contentElement, setContentElement] = useState(null);
  const [overlayElement, setOverlayElement] = useState(null);

  OverlayScrollbars.plugin(ClickScrollPlugin);

  useEffect(() => {
    if (!contentElement || !focusOnOpen) return;
    pause(350).then(() => focusFirstField(contentElement));
  }, [contentElement]);

  function handleAfterOpen(event) {
    afterOpen?.(event);
  }

  function handleCloseModal(event) {
    if (document.body.classList.contains("select-open")) return;
    close?.(event);
  }

  return (
    <ModalContextProvider
      elements={{
        contentElement,
        overlayElement,
      }}
    >
      <ReactModal
        id={id}
        className="modal-container-shadow"
        overlayClassName={clsx("modal", `${className}-overlay`)}
        isOpen={isOpen}
        onAfterOpen={handleAfterOpen}
        onRequestClose={handleCloseModal}
        onAfterClose={afterClose}
        contentLabel={contentLabel}
        ariaHideApp={ariaHideApp || false}
        closeTimeoutMS={200}
        style={{
          overlay: {
            ...overlayStyles,
          },
          content: {
            ...contentStyles,
            "--modal-width": customWidth,
          },
        }}
        overlayRef={setOverlayElement}
        ref={ref}
      >
        <div className={clsx("modal-container", className)}>
          {!hideHeader && (
            <header>
              <p>{title || "Modal Title"}</p>
              {!hideCloseBtn && (
                <IconX
                  onClick={close}
                  tabIndex={0}
                  stroke={2}
                  onKeyDown={(event) => keyEnter(event, close)}
                />
              )}
            </header>
          )}
          <div
            className={clsx("modal-content", contentClassName)}
            ref={setContentElement}
          >
            {children && (
              <OverlayScrollbarsComponent
                element={scrollElement || "form"}
                className="scroll-container"
                options={{
                  scrollbars: {
                    clickScroll: true,
                  },
                }}
                defer
              >
                <div className="modal-content-bounds">{children}</div>
              </OverlayScrollbarsComponent>
            )}
          </div>
        </div>
      </ReactModal>
    </ModalContextProvider>
  );
});

/** @param {{ deps: React.DependencyList }} */
export const ScrollToError = ({ deps }) => {
  const { contentElement } = useContext(ModalContext);
  const [scrollElement, setScrollElement] = useState(null);
  const smoothScroller = zenScroll.createScroller(scrollElement);

  const parsedDeps = deps instanceof Array ? deps : [];

  useEffect(() => {
    if (!contentElement) return;
    pause(100).then(() => {
      const scrollElementAttr = "[data-overlayscrollbars-viewport]";
      const element = contentElement.querySelector(scrollElementAttr);
      if (element) setScrollElement(element);
    });
  }, [contentElement]);

  useEffect(() => {
    scrollToError();
  }, parsedDeps);

  async function scrollToError() {
    if (!scrollElement) return;
    await pause(500);
    const field = scrollElement.querySelector(".field-error:not(.disabled)");
    if (!field) return;
    const scrollDuration = scrollElement.scrollHeight;
    const y = smoothScroller.getTopOf(field);
    smoothScroller.toY(y, scrollDuration, async () => {
      await pause(150);
      field.querySelector("input, textarea").focus();
    });
  }
};

/** @param {HTMLElement} modalContent */
export async function swapContent(modalContent, toDo) {
  if (!modalContent) return;
  modalContent.style.setProperty("--scrollbar-opacity", 0);
  await pause(100);
  const innerContent = modalContent.querySelector(
    "div[data-overlayscrollbars-viewport]",
  );
  if (!innerContent) return;
  const childBounds = innerContent.children[0];
  innerContent.style.opacity = 0;
  innerContent.style.minHeight = `${innerContent.offsetHeight}px`;
  innerContent.style.maxHeight = `${innerContent.offsetHeight}px`;
  await toDo?.();
  if (!document.contains(innerContent)) return;
  innerContent.style.opacity = "100%";
  innerContent.style.minHeight = `${childBounds.scrollHeight}px`;
  innerContent.style.maxHeight = `${childBounds.scrollHeight}px`;
  await pause(100);
  modalContent.style.setProperty("--scrollbar-opacity", "60%");
  await pause(50);
  innerContent.style.minHeight = null;
  innerContent.style.maxHeight = null;
}

export function focusFirstField(modalContent) {
  if (!modalContent) return;
  const firstInput = modalContent.querySelector("input:not(:disabled)");
  if (firstInput) {
    firstInput.focus();
  }
}

/**
 * @param {Element} modalContent
 * @returns {Element | null}
 */
export function getScrollElement(modalContent) {
  return modalContent.querySelector("[data-overlayscrollbars-viewport]");
}

export default Modal;
