import {
  IconAlertCircleFilled,
  IconChevronDown,
  IconEye,
  IconEyeClosed,
  IconLock,
} from "@tabler/icons-react";
import clsx from "clsx";
import {
  forwardRef,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { pause } from "../Utils";
import { ModalContext } from "../contexts/Contexts";
import "../styles/input.css";
import ScrollList from "./ScrollList";
import Tooltip from "./Tooltip";

/** @type {ForwardRef<InputFieldProps, HTMLSpanElement>} */
const InputField = forwardRef((props, ref) => {
  const {
    id,
    className,
    label,
    iconLeft,
    iconRight,
    width,
    type,
    placeholder,
    value,
    hideValue,
    defaultValue,
    autoComplete,
    onChange,
    filters,
    disabled,
    readOnly,
    textArea,
    error,
    style,
  } = props;

  const [pwType, setPwType] = useState("password");
  const [eyeVisible, setEyeVisible] = useState(false);
  const [eyeClosed, setEyeClosed] = useState(false);
  const [iconLeftPad, setIconLeftPad] = useState(null);
  const [iconRightPad, setIconRightPad] = useState(null);
  const [iconGroupLeft, setIconGroupLeft] = useState(null);
  const [iconGroupRight, setIconGroupRight] = useState(null);
  const [inputElement, setInputElement] = useState(null);
  const [filteredValue, setFilteredValue] = useState(value);
  const [cursorPos, setCursorPos] = useState({ start: 0, end: 0 });
  const iconGroupLeftResizerRef = useRef(null);
  const iconGroupRightResizerRef = useRef(null);

  const modalContent = useContext(ModalContext).contentElement || {};
  const InputComponent = textArea ? "textarea" : "input";
  const pwField = type === "password";

  useEffect(() => {
    observeIconGroup({
      group: iconGroupLeft,
      ref: iconGroupLeftResizerRef,
      setPad: setIconLeftPad,
    });
  }, [iconGroupLeft]);

  useEffect(() => {
    observeIconGroup({
      group: iconGroupRight,
      ref: iconGroupRightResizerRef,
      setPad: setIconRightPad,
    });
  }, [iconGroupRight]);

  useEffect(() => {
    if (!inputElement) return;
    if (readOnly && value) {
      pause(10).then(() => {
        const event = new Event("change");
        inputElement.dispatchEvent(event);
        handleInputChange(event);
      });
    }
  }, [value]);

  useLayoutEffect(() => {
    if (!inputElement) return;
    inputElement.setSelectionRange(cursorPos.start, cursorPos.end);
  }, [cursorPos, filteredValue]);

  /**
   * @param {{
   *    group: Element,
   *    ref: React.MutableRefObject<ResizeObserver>,
   *    setPad: UseState<string | number>[1],
   * }}
   */
  function observeIconGroup({ group, ref, setPad }) {
    if (!group) return;
    if (!ref.current) {
      ref.current = new ResizeObserver((e) => getIconPadding(e, setPad));
    } else {
      ref.current.disconnect();
    }
    ref.current.observe(group);
  }

  /**
   *
   * @param {ResizeObserverEntry[]} entries
   * @param {UseState<string | number>[1]} setPad
   */
  function getIconPadding(entries, setPad) {
    if (!entries) return;
    const [iconGroup] = entries;
    const iconGroupBox = iconGroup.borderBoxSize[0];
    const iconGroupRect = iconGroup.contentRect;
    if (!iconGroupRect.width) {
      setPad("0.5rem");
    } else {
      setPad(`calc(${iconGroupBox.inlineSize}px + 0.5rem)`);
    }
  }

  function getTextAreaIconPad() {
    if (!iconGroupRight) return "unset";
    const { paddingBottom } = window.getComputedStyle(iconGroupRight);
    if (iconGroupRight.clientHeight <= parseInt(paddingBottom)) return "unset";
    return `${iconGroupRight.clientHeight}px`;
  }

  function toggleVisibility(event) {
    event.preventDefault();
    if (event.type === "mousedown" && event.button !== 0) return;
    const targetClass = event.target.classList;
    const visible = targetClass.contains("visible");
    const field = event.target.parentNode.parentNode;
    const input = field.querySelector("input");
    if (input) {
      input.style.setProperty("--text-security", visible ? "none" : "disc");
    }
    const toggle = targetClass.contains("toggle-pw-visible");
    if (toggle) {
      setEyeClosed(!eyeClosed);
      pwField && setPwType(visible ? "text" : "password");
    }
  }

  /**
   * @param {string} value
   * @returns {string}
   */
  function getFilteredValue(value) {
    if (!filters) return value;
    for (const filter of filters) {
      value = filter(value);
    }
    return value;
  }

  function updateCursorPos(event) {
    return {
      start: event.target.selectionStart,
      end: event.target.selectionEnd,
    };
  }

  /** @type {InputChangeHandler} */
  function handleInputChange(event) {
    if (!inputElement) return;

    if (!readOnly) {
      const updatedVal = getFilteredValue(inputElement.value);
      setFilteredValue(updatedVal);

      const newCursorPos = updateCursorPos(event);
      const enteredChar = event.nativeEvent.data;
      const filteredChar = updatedVal.charAt(cursorPos.start);
      if (!enteredChar || getFilteredValue(enteredChar) === filteredChar) {
        setCursorPos(newCursorPos);
      } else {
        setCursorPos({ ...cursorPos });
      }
    }
    setEyeVisible(!!event.target.value.trim());
    onChange?.(event);
  }

  function checkRightClick(event) {
    if (event.button !== 0) {
      event.preventDefault();
    }
  }

  return (
    <span
      className={clsx(
        `${InputComponent}-field`,
        error && "field-error",
        disabled && "disabled",
        !label && "no-label",
      )}
      style={{ width }}
      ref={ref}
    >
      {!textArea && iconLeft && (
        <span className="input-main-icon" ref={setIconGroupLeft}>
          {iconLeft}
        </span>
      )}
      <InputComponent
        id={id}
        type={pwField ? pwType : type || "text"}
        className={clsx(className, hideValue && "hide-value")}
        value={readOnly ? value : filteredValue}
        defaultValue={defaultValue}
        placeholder={placeholder}
        autoComplete={autoComplete}
        disabled={disabled}
        readOnly={readOnly}
        onBeforeInput={(e) => setCursorPos(updateCursorPos(e))}
        onChange={handleInputChange}
        tag={pwField ? "password" : ""}
        onMouseDown={checkRightClick}
        ref={setInputElement}
        style={{
          ...style,
          paddingLeft: !textArea && iconLeftPad,
          paddingRight: !textArea && iconRightPad,
          borderBottomWidth: textArea && getTextAreaIconPad(),
        }}
      />
      {label && <label htmlFor={id}>{label}</label>}
      <span
        className={textArea ? "textarea-icons" : "input-icons"}
        ref={setIconGroupRight}
      >
        <Tooltip
          className="err-tooltip"
          content={error || "--"}
          disabled={!error}
          appendTo={modalContent.parentNode || document.body}
          delay={[250, null]}
        >
          <IconAlertCircleFilled
            className="error-info"
            onMouseDown={(event) => event.preventDefault()}
            display={error ? "block" : "none"}
            tabIndex={-1}
          />
        </Tooltip>
        {!disabled && iconRight}
        {(pwField || hideValue) && eyeVisible && (
          <>
            <IconEye
              className="toggle-pw-visible visible"
              onMouseDown={toggleVisibility}
              display={!eyeClosed ? "block" : "none"}
            />
            <IconEyeClosed
              className="toggle-pw-visible"
              onMouseDown={toggleVisibility}
              display={eyeClosed ? "block" : "none"}
            />
          </>
        )}
        {disabled && <IconLock className="input-locked" />}
      </span>
    </span>
  );
});

/** @param {{ options: object } & InputFieldProps} props */
export const SelectField = (props) => {
  const {
    id,
    className,
    placeholder,
    value,
    width,
    options = {},
    onChange,
    disabled,
    ...otherProps
  } = props;

  const container = useContext(ModalContext).overlayElement || document.body;

  const [selected, setSelected] = useState(value);
  const [fieldElement, setFieldElement] = useState(null);
  const [optionsOpen, setOptionsOpen] = useState(false);
  const [tooltipInst, setTooltipInst] = useState(null);
  const [parsedOptions, setParsedOptions] = useState({});

  useEffect(() => {
    let parsed = options;
    if (typeof options !== "object") {
      parsed = parseToObj([`${options}`]);
    } else if (options instanceof Array) {
      parsed = parseToObj(options);
    }
    setParsedOptions(parsed);
  }, [options]);

  useEffect(() => {
    setSelected(value);
  }, [value]);

  function parseToObj(arr = []) {
    return arr.reduce((prev, cur) => ({ ...prev, [cur]: cur }), {});
  }

  async function handleSelection(event) {
    /** @type {HTMLSpanElement} */
    const option = event.target;
    if (!option || !tooltipInst) return;

    await pause(150);
    tooltipInst.hide();
    await pause(tooltipInst.props.duration);
    setOptionsOpen(false);
    await pause(20);
    setSelected(event.target.id);
    fieldElement.firstChild.focus();
  }

  /** @param {React.MouseEvent<HTMLElement>} event */
  function handleInputOnClick(event) {
    if (event.target.nodeName === "INPUT" && !optionsOpen) {
      setOptionsOpen(true);
    } else {
      tooltipInst?.hide?.();
    }
  }

  /** @param {React.KeyboardEvent<HTMLSpanElement>} event */
  function handleKeyDownOption(event) {
    if (event.ctrlKey || event.altKey) return;

    if (event.key === "Tab") {
      tooltipInst?.hide?.();
      return;
    }
    event.preventDefault();
    event.stopPropagation();

    /** @type {HTMLSpanElement} */
    const option = event.target;
    if (!option) return;

    const parent = option.parentElement;
    switch (event.key) {
      case "Enter":
        option.classList.add("clicked");
        option.click();
        pause(200).then(() => {
          option.classList.remove("clicked");
        });
        break;
      case "ArrowDown":
        if (parent.lastChild === option) {
          parent.firstChild.focus();
        } else {
          option.nextSibling.focus();
        }
        break;
      case "ArrowUp":
        if (parent.firstChild === option) {
          parent.lastChild.focus();
        } else {
          option.previousSibling.focus();
        }
        break;
      case "Escape":
        tooltipInst?.hide?.();
        fieldElement.firstChild.focus();
        break;
      default:
        break;
    }
  }

  function handleKeyDownField(event) {
    switch (event.key) {
      case "Enter":
        event.preventDefault();
        event.stopPropagation();
        event.target.click();
        fieldElement.firstChild.focus();
        break;
      case "Escape":
        if (optionsOpen) {
          event.preventDefault();
          event.stopPropagation();
          tooltipInst?.hide?.();
          fieldElement.firstChild.focus();
          break;
        }
      default:
        break;
    }
  }

  /** @param {HTMLSpanElement} option */
  async function focusOnOpen(option) {
    const currSelection = selected !== undefined && option?.id === selected;
    const firstOption = option && !option?.previousElementSibling;

    if (currSelection || firstOption) {
      await pause(50);
      option.focus();
    }
  }

  const optionsList = (
    <ScrollList
      className="options-list"
      direction="vertical"
      borderRadius="0.3rem"
      background="var(--bubble-bg-color)"
      style={{ width: `calc(${width} - 0.4rem)` }}
      defer
    >
      {Array.from(Object.entries(parsedOptions), ([value, label]) => (
        <span
          key={value}
          tabIndex={-1}
          id={value}
          className={clsx("option", value === selected && "selected")}
          onClick={handleSelection}
          onMouseOver={(e) => e.target.focus()}
          onMouseMove={(e) => e.target.focus()}
          onKeyDown={handleKeyDownOption}
          ref={focusOnOpen}
        >
          {label}
        </span>
      ))}
    </ScrollList>
  );

  return (
    <Tooltip
      className="select-tooltip"
      content={optionsList}
      onShow={setTooltipInst}
      onHidden={() => setOptionsOpen(false)}
      onClickOutside={async (inst) => {
        inst.hide();
        document.body.classList.add("select-open");
        await pause(inst.props.delay[1]);
        document.body.classList.remove("select-open");
      }}
      popperOptions={{
        modifiers: [
          {
            name: "preventOverflow",
            options: {
              boundary: document.body,
              altAxis: true,
            },
          },
        ],
      }}
      placement="bottom"
      appendTo={container}
      visible={optionsOpen && !disabled}
      arrow={null}
      interactive
      offset={0}
    >
      <div
        style={{ zIndex: optionsOpen && 10 }}
        aria-expanded={optionsOpen}
        onClick={handleInputOnClick}
        onKeyDown={handleKeyDownField}
      >
        <InputField
          id={id}
          className="select-value-hidden"
          value={selected || ""}
          onChange={onChange}
          readOnly
        />
        <InputField
          {...otherProps}
          className={clsx("select-field", className)}
          placeholder={placeholder || "Select"}
          value={parsedOptions[selected] || ""}
          iconRight={
            <IconChevronDown
              className="select-arrow"
              stroke={2}
              style={{ transform: optionsOpen && "scaleY(-1)" }}
            />
          }
          width={width}
          disabled={disabled}
          readOnly
          ref={setFieldElement}
        />
      </div>
    </Tooltip>
  );
};

/**
 * @param {{
 *    label: string,
 *    className: string,
 *    style: React.CSSProperties
 * }} props
 */
export function InputHeader(props) {
  const { label, className, style } = props;
  return (
    <span className={clsx("label", className)} style={style}>
      <div />
      <h2>{label}</h2>
      <div />
    </span>
  );
}

export default InputField;
