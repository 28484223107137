import clsx from "clsx";
import { forwardRef } from "react";
import { Button } from "./Button";

/** @type {ForwardRef<ButtonToggleProps, HTMLDivElement>} */
const ButtonToggle = forwardRef((props, ref) => {
  const {
    id,
    className,
    variant,
    depth,
    bgColor,
    tabIndex,
    fontSize,
    choices,
    firstOn,
    actions,
    stacked,
    chosenColor,
    tippys,
    styles,
  } = props;

  const parsedVariant = variant || "secondary";

  // ensure choices is valid array
  let parsedChoices = choices || ["option 1", "option 2"];
  if (choices && !Array.isArray(choices)) {
    parsedChoices = Array.from(parsedChoices);
  }

  // handle choices as labels or icons
  let parsedLabels = ["", ""];
  let parsedIcons = [null, null];
  for (let i in parsedChoices) {
    typeof parsedChoices[i] === "string"
      ? (parsedLabels[i] = parsedChoices[i])
      : (parsedIcons[i] = parsedChoices[i]);
  }

  // get given functions for choices
  let parsedActions = [null, null];
  if (actions) {
    parsedActions[0] = actions[0] || actions;
    parsedActions[1] = actions[1] || parsedActions[0];
  }

  let parsedTippys = [];
  if (tippys) {
    parsedTippys[0] = tippys[0] || tippys;
    parsedTippys[1] = tippys[1] || parsedTippys[0];
  }

  return (
    <div
      id={id}
      className={clsx(
        "btn-toggle",
        className,
        parsedVariant,
        stacked && "stacked",
      )}
      style={{
        ...styles,
        "--btn-toggle-depth": depth,
        "--btn-toggle-bg-color": bgColor,
        "--btn-toggle-font-size": fontSize,
      }}
      ref={ref}
    >
      <Button
        className="choice1"
        label={parsedLabels[0]}
        icon={parsedIcons[0]}
        onClick={(event) => parsedActions[0]?.(event)}
        tag={firstOn ? "pressed" : null}
        variant={variant || (firstOn ? "light" : "secondary")}
        styles={{ "--toggle-btn-bg": firstOn && chosenColor }}
        tabIndex={tabIndex}
        tippy={parsedTippys[0]}
      />
      <Button
        className="choice2"
        label={parsedLabels[1]}
        icon={parsedIcons[1]}
        onClick={(event) => parsedActions[1]?.(event)}
        tag={!firstOn ? "pressed" : null}
        variant={variant || (!firstOn ? "light" : "secondary")}
        styles={{ "--toggle-btn-bg": !firstOn && chosenColor }}
        tabIndex={tabIndex + 1}
        tippy={parsedTippys[1]}
      />
    </div>
  );
});

export default ButtonToggle;
