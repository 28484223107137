import { compiler } from "markdown-to-jsx";
import { useContext, useEffect } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { ScenarioInfo } from "../contexts/Contexts";

/** @type {import("markdown-to-jsx").MarkdownToJSX.Options} */
const mdToJsxOptions = {
  wrapper: null,
  overrides: {
    h1: {
      component: "header",
      props: {
        id: null,
      },
    },
  },
};

/**
 *
 * @param {HTMLElement} subtree
 * @param {object} parent
 */
function parseHTML(subtree, parent = {}) {
  if (!subtree) return null;

  const treeKey = subtree.localName;
  const newNodes = {};

  for (let child of subtree.childNodes) {
    const childKey = child.localName;

    if (childKey === "div" && child.className === "instruction") {
      return child.innerHTML;
    }
    newNodes[childKey] = parseHTML(child, parent[treeKey]);
  }
  const notArrayItem = (key) => !key.match(/arr-[0-9]+/g);
  const isArray = !Object.keys(newNodes).some(notArrayItem);
  if (isArray) return Object.values(newNodes);

  return newNodes;
}

export async function parseMarkdown(contentDir = "") {
  const taskNesting = contentDir.split("/");

  let mdPath = "";
  if (taskNesting.length === 3) {
    const [mid, sid, aid] = taskNesting;
    mdPath = await import(
      `../assets/module-content/${mid}/${sid}/${aid}/instructions.md`
    );
  } else if (taskNesting.length === 2) {
    const [mid, sid] = taskNesting;
    mdPath = await import(
      `../assets/module-content/${mid}/${sid}/instructions.md`
    );
  } else if (taskNesting.length === 1) {
    const [intro] = taskNesting;
    mdPath = await import(`../assets/module-content/${intro}/instructions.md`);
  } else {
    return {};
  }

  return await fetch(mdPath.default)
    .then((res) => res.text())
    .then((md) => compiler(md, mdToJsxOptions))
    .then(renderToStaticMarkup)
    .then((html) => new DOMParser().parseFromString(html, "text/html").body)
    .then(parseHTML)
    .catch(console.log);
}

export const InstructionParser = () => {
  const {
    states: { activityState },
    setStates: { setInstructionJSON },
  } = useContext(ScenarioInfo);

  useEffect(() => {
    if (!activityState.taskId) return;

    parseMarkdown(`${activityState.assetPath}`).then(setInstructionJSON);
  }, [activityState]);
};
