import { IconLoader2 } from "@tabler/icons-react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { defaults, getSubset } from "../Utils";
import { ScenarioInfo } from "./Contexts";

/** @type {React.Context<ScenarioStates>["Provider"]} */
const ScenarioInfoProvider = ({ children }) => {
  const location = useLocation();

  /** @type {ActivityState} */
  const defaultActivity = getSubset(
    location.state || {},
    defaults.activityState,
  );

  const [playerLoading, setPlayerLoading] = useState(true);
  const [frameOpacity, setFrameOpacity] = useState(0);
  const [avatarVariant, setAvatarVariant] = useState("default");
  const [activityState, setActivityState] = useState({ ...defaultActivity });
  const [isCompleted, setIsCompleted] = useState(null);
  const [hasCorrectAnswer, setHasCorrectAnswer] = useState(null);
  const [responses, setResponses] = useState({ ...defaults.responseHistory });
  const [instructionJSON, setInstructionJSON] = useState({
    ...defaults.instructionsHA,
  });
  const [currInstructions, setCurrInstructions] = useState("");
  const [sectionedProgressData, setSectionedProgressData] = useState([""]);
  const [continuousProgressData, setContinuousProgressBarData] = useState(0);
  const [scenarioWindow, setScenarioWindow] = useState(null);
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);

  // HA States
  const [isDriverView, setIsDriverView] = useState(false);

  // AM States
  const [topView, setTopView] = useState(false);
  const [topViewUpNext, setTopViewUpNext] = useState(false);
  const [mirrorView, setMirrorView] = useState(false);
  const [mirrorDisabled, setMirrorDisabled] = useState(true);
  const [showQuestion, setShowQuestion] = useState(false);
  const [noGlanceMade, setNoGlanceMade] = useState(false);
  const [isGridAttempted, setIsGridAttempted] = useState(false);
  const [allowReset, setAllowReset] = useState(false);
  const [performReset, setPerformReset] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [gridSelections, setGridSelections] = useState([]);
  const [ended, setEnded] = useState(false);

  return (
    <ScenarioInfo.Provider
      value={{
        states: {
          playerLoading,
          frameOpacity,
          avatarVariant,
          activityState,
          isCompleted,
          hasCorrectAnswer,
          responses,
          instructionJSON,
          currInstructions,
          sectionedProgressData,
          continuousProgressData,
          scenarioWindow,
          isFeedbackOpen,
          // HA
          isDriverView,
          // AM
          topView,
          topViewUpNext,
          mirrorView,
          mirrorDisabled,
          showQuestion,
          noGlanceMade,
          isGridAttempted,
          allowReset,
          performReset,
          playing,
          gridSelections,
          ended,
        },
        setStates: {
          setPlayerLoading,
          setFrameOpacity,
          setAvatarVariant,
          setActivityState,
          setIsCompleted,
          setHasCorrectAnswer,
          setResponses,
          setInstructionJSON,
          setCurrInstructions,
          setSectionedProgressData,
          setContinuousProgressBarData,
          setScenarioWindow,
          setIsFeedbackOpen,
          // HA
          setIsDriverView,
          // AM
          setTopView,
          setTopViewUpNext,
          setMirrorView,
          setMirrorDisabled,
          setShowQuestion,
          setNoGlanceMade,
          setIsGridAttempted,
          setAllowReset,
          setPerformReset,
          setPlaying,
          setGridSelections,
          setEnded,
        },
        actions: {},
      }}
    >
      {!frameOpacity && <IconLoader2 className="page-loading" />}
      {children}
    </ScenarioInfo.Provider>
  );
};

export default ScenarioInfoProvider;
