import { clsx } from "clsx";
export default function Symbol(props) {
  return (
    <div
      className={clsx(
        "symbol",
        props.letter === "H"
          ? "f-teal"
          : props.letter === "A"
            ? "f-blue"
            : props.letter === "C"
              ? "f-violet"
              : props.letter === "V"
                ? "f-yellow"
                : None,
        props.stroke && "f-stroke",
      )}
    >
      <div>{props.letter}</div>
    </div>
  );
}
