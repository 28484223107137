import { useStateRef } from "@src/Hooks";
import Tooltip, { animateTooltip } from "@src/components/Tooltip";
import { clsx } from "clsx";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { pause } from "../Utils";
import "../styles/button.css";

/** @type {ForwardRef<ButtonProps, HTMLButtonElement>} */
export const Button = forwardRef((props, ref) => {
  const {
    icon,
    tippy,
    label,
    variant,
    disabled,
    tag,
    onClick,
    id,
    type,
    className,
    ariaLabel,
    depth,
    bgColor,
    fontSize,
    gap: gap,
    styles,
    tabIndex,
    reverse,
  } = props;

  const [active, setActive] = useState("");
  const [button, setButton, buttonRef] = useStateRef(null);
  const tooltipRef = useRef(null);
  let hoverTimeout = null;
  let holdTimeout = null;

  // check whether given icon is a valid, non-empty object or string
  const objIcon = icon && typeof icon === "object";
  const strIcon = icon && typeof icon === "string";
  const validIcon = strIcon || objIcon;
  const { className: tippyClass, ...tippyRest } = tippy || {};

  // parse label as string
  const strLabel = label ? `${label}` : "";

  // put together the button attributes
  const btnType = validIcon && label ? "icon-" : "";
  const btnVariant = variant || "light";
  const emptyTag = !(label || validIcon) ? "btn-empty" : "";
  const attr = [btnVariant, emptyTag];

  // prettier-ignore
  useImperativeHandle(ref, () => {
    return buttonRef.current;
  }, [button]);

  useEffect(() => {
    if (!button) return;
    button.addEventListener(
      "touchend",
      (event) => {
        if (event.cancelable) event.preventDefault();
        return false;
      },
      { passive: false },
    );
  }, [button]);

  /** @param {MouseEvent} event */
  function mouseClick(event) {
    const pointerType = event.nativeEvent.pointerType;
    const mozInput = event.nativeEvent.mozInputSource;
    return pointerType === "mouse" || mozInput === 1;
  }

  /** @param {MouseEvent} event */
  async function handleClick(event) {
    if (disabled || !event.cancelable) return;
    event.preventDefault();
    if (!mouseClick(event)) {
      setActive("active");
      await pause(200);
      setActive("");
    }
    if (!tag) {
      !mouseClick(event) && (await pause(100));
      onClick?.(event);
    }
  }

  function handleTouchStart() {
    holdTimeout = setTimeout(() => {
      const tooltip = tooltipRef.current;
      if (tooltip) tooltip.show();
      holdTimeout = null;
    }, 500);
  }

  function handleTouchEnd(event) {
    const { clientX, clientY } = event.changedTouches[0];
    const touched = document.elementFromPoint(clientX, clientY);
    const tooltip = tooltipRef.current;
    if (tooltip) tooltip.hide();
    if (!holdTimeout) return;
    clearTimeout(holdTimeout);
    if (button && button.contains(touched)) {
      handleClick(event);
    }
  }

  function tooltipShow(inst) {
    const hideAfter = tippyRest.hideAfter || 2500;
    animateTooltip(inst, hideAfter).then(async (timeout) => {
      hoverTimeout = timeout;
      const old = timeout;
      await pause(hideAfter);
      if (old !== hoverTimeout) return;
      if (!inst.state.isDestroyed) inst.hide();
    });
  }

  return (
    <Tooltip
      className={clsx("btn-tooltip", tippyClass)}
      onCreate={(inst) => (tooltipRef.current = inst)}
      onShow={tooltipShow}
      onHide={() => clearTimeout(hoverTimeout)}
      disabled={!tippyRest.content || !button || !button.clientHeight}
      appendTo={button ? button.parentNode : null}
      hideOnClick
      zIndex={1}
      delay={[500, 0]}
      {...tippyRest}
    >
      <button
        id={id}
        type={type || "button"}
        className={clsx(
          "btn",
          `${btnType}btn-wrapper`,
          className,
          disabled && "disabled",
          attr,
        )}
        onClick={handleClick}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        aria-label={ariaLabel || strLabel}
        aria-disabled={disabled}
        style={{
          "--btn-depth": depth,
          "--btn-bg-color": bgColor,
          "--btn-font-size": fontSize,
          "--btn-gap": gap,
          ...styles,
        }}
        tag={tag || active}
        tabIndex={tabIndex || (disabled ? -1 : 0)}
        ref={setButton}
      >
        <span className="btn-foreground">
          {reverse && label && <span>{strLabel}</span>}
          {validIcon && <div className="btn-icon">{icon}</div>}
          {!reverse && label && <span>{strLabel}</span>}
          <div className="load-dots" />
        </span>
        <span className="btn-depth" />
        <span className="btn-background" />
      </button>
    </Tooltip>
  );
});

/** @param {HTMLButtonElement} btn */
export async function shake(btn) {
  if (!btn) return;
  btn.classList.add("shaking");
  await pause(400);
  btn.classList.remove("shaking");
}

/** @param {HTMLButtonElement} btn */
export function setLoading(btn) {
  if (!btn) return;
  btn.style.minWidth = `${btn.clientWidth}px`;
  btn.style.height = `${btn.clientHeight}px`;
  btn.classList.add("loading");
}

/** @param {HTMLButtonElement} btn */
export function stopLoading(btn) {
  if (!btn) return;
  btn.style.minWidth = "";
  btn.style.height = "";
  btn.classList.remove("loading");
}

/**
 * @param {HTMLButtonElement} btn
 * @param {function} toDo
 */
export async function swapLabel(btn, toDo) {
  if (!btn) return;
  btn.classList.add("swapping");
  await pause(100);
  await toDo?.();
  await pause(100);
  btn.classList.remove("swapping");
}
