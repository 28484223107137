import { IconExclamationCircle } from "@tabler/icons-react";
import { useState } from "react";
import { pause } from "../Utils";
import { Button } from "./Button";
import Modal from "./Modal";

// function ByPassModal(props) {
//   return (
//     <>
//       <Modal>
//         <div>Contact your instructor for permission</div>
//         <Button label="Continue learning"></Button>
//       </Modal>
//     </>
//   );
// }

export default function TrainingPausedModal(props) {
  const [modalOpen, setModalOpen] = props.modalState;
  const [showWarning, setShowWarning] = useState(false);

  async function closeModal(event) {
    setShowWarning(false);
    await pause(100);
    event.preventDefault();
    setModalOpen(false);
    await pause(200);
  }

  function continueTraining() {
    if (showWarning) {
      setShowWarning(false);
      props.continueModule();
    } else {
      setShowWarning(true);
    }
  }

  return (
    <Modal
      id="paused-modal"
      className="paused-modal"
      title={"Training Paused"}
      isOpen={modalOpen}
      close={closeModal}
      focusOnOpen
    >
      <div className="pause-message">
        <header>Great work so far!</header>
        <p>
          Now is a good time to pause before you continue with training. Please
          confirm with your instructor before proceeding.
        </p>
      </div>
      <div className="btn-row">
        <Button
          label={"Go Back"}
          className="btn-back"
          variant="light"
          onClick={closeModal}
        />
        {showWarning && (
          <div className="warning-message">
            <IconExclamationCircle height="2em" width="2em" stroke={1.3} />
            <p>Are you sure you want to continue?</p>
          </div>
        )}
        <Button
          label={"Continue"}
          className="btn-continue"
          variant="secondary"
          onClick={continueTraining}
        />
      </div>
    </Modal>
  );
}
