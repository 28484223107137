import { useNavigate } from "react-router-dom";
import { Button } from "../components/Button";
import "../styles/study-concluded.css";

function ValidationStudyUser() {
  const navigate = useNavigate();
  document.body.id = "study-concluded";
  return (
    <>
      <header>Study Concluded</header>
      <p className="concluded-msg">
        Thank you for your interest, but this study has concluded. Please email{" "}
        <a href={"mailto:transportresearch@jhu.edu"}>
          TransportResearch@jhu.edu
        </a>{" "}
        if you have any questions.
      </p>
      <Button
        label="Return Home"
        variant="light"
        depth="1.2rem"
        onClick={() => {
          navigate("/home");
        }}
        fontSize="2rem"
      />
    </>
  );
}

export default ValidationStudyUser;
