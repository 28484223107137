import { forwardRef, useEffect, useRef, useState } from "react";

/** @type {ForwardRef<SVGProps, SVGElement>} */
export const SVG = forwardRef((props, ref) => {
  const { src, fadeDurationMS, style, ...svgProps } = props;

  const [loading, setLoading] = useState(true);
  const [newSvg, setNewSvg] = useState(null);
  const [opacity, setOpacity] = useState(0);
  const fadeTimeout = useRef(null);
  const SVGRef = useRef(null);

  function updateSVG() {
    SVGRef.current = newSvg.default;
    setLoading(false);
  }

  useEffect(() => {
    if (!newSvg) return;
    if (fadeDurationMS) {
      setOpacity(0);
      updateSVG();
      fadeTimeout.current = setTimeout(() => {
        setOpacity(1);
      }, fadeDurationMS);
    } else {
      updateSVG();
      setOpacity(1);
    }
  }, [newSvg]);

  useEffect(() => {
    if (fadeDurationMS) clearTimeout(fadeTimeout.current);
    setLoading(true);
    import(`../assets/${src}.svg?react`)
      .then((res) => setNewSvg(res))
      .catch((err) => console.log(err));
  }, [src]);

  return (
    SVGRef.current && (
      <SVGRef.current
        opacity={opacity}
        style={{
          ...style,
          transition: fadeDurationMS ? `${fadeDurationMS}ms ease` : null,
        }}
        ref={ref}
        {...svgProps}
      />
    )
  );
});
