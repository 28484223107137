import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import Card from "../components/Card";
import "../styles/colors.css";
import "../styles/research-dashboard.css";

ChartJS.register(ArcElement, Tooltip, Legend);

export function DonutGraph(props) {
  const data = {
    datasets: [
      {
        data: props.data,
        backgroundColor: ["#213760", "#6591D3"],
        display: true,
        borderWidth: 0,
      },
    ],
  };
  return (
    <div className="graph-cont">
      <Doughnut
        className="donut"
        data={data}
        options={{
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: true,
            },
          },
          rotation: -90,
          circumference: 180,
          cutout: "60%",
          maintainAspectRatio: true,
          responsive: true,
        }}
      />
      <div className="graph-percentage">
        {Math.ceil((props.data[0] / (props.data[0] + props.data[1])) * 100)}%
      </div>
    </div>
  );
}

export default function DonutGraphCont(props) {
  return (
    <Card className="viz-card" width="20%" bgColor="red">
      <DonutGraph data={props.data} />
      <h2>{props.title}</h2>
      <h3>{props.subTitle}</h3>
      {props.buttonLabel && (
        <>
          <div
            className={`viz-email-btn ${props.disabled && "disabled"}`}
            onClick={() => {
              // !props.disabled && props.setModalState({ action: props.title });
              !props.disabled && props.onClick();
            }}
          >
            {props.buttonLabel}
          </div>
        </>
      )}
    </Card>
  );
}
