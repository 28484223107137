import {
  IconArrowLeft,
  IconCheck,
  IconExclamationCircle,
  IconFile,
  IconTrash,
  IconUpload,
  IconWorldCancel,
} from "@tabler/icons-react";
import axios from "axios";
import clsx from "clsx";
import { useContext, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import zenScroll from "zenscroll";
import { BannerContext, ServerUrl, pause, xlsxToJson } from "../Utils";
import { ModalContext } from "../contexts/Contexts";
import { Button, setLoading, shake, stopLoading, swapLabel } from "./Button";
import { getScrollElement, swapContent } from "./Modal";

function ResearcherTools({ isOpen, setIsOpen, userInfo }) {
  const [fileToUpload, setFileToUpload] = useState(null);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [showCancelBtn, setShowCancelBtn] = useState(false);
  const uploadFieldRef = useRef(null);
  const deleteWarnRef = useRef(null);
  const deleteBtnRef = useRef(null);
  const trashBtnRef = useRef(null);

  const [banner, setBanner] = useContext(BannerContext);
  const { contentElement } = useContext(ModalContext);
  const navigate = useNavigate();

  function handleUpload(event) {
    if (confirmDelete) cancelDelete();

    const btn = event.target;
    if (fileToUpload) {
      setLoading(btn);
      xlsxToJson(fileToUpload).then(async (data) => {
        await axios
          .post(
            `${ServerUrl}/users/upload`,
            {
              data,
            },
            {
              withCredentials: true,
            },
          )
          .then(async (res) => {
            clearFile();
            await pause(500);
            setUploadSuccess(true);
            stopLoading(btn);
            await pause(2500);
            swapLabel(btn, () => setUploadSuccess(false));
          })
          .catch((err) => {
            // temporary error handling
            console.log(err.response.data.message.split(",").join("\n"));

            if (err.code === "ERR_NETWORK") {
              setBanner({
                icon: <IconWorldCancel />,
                text: "Upload failed due to network error",
              });
            }
            /* TODO: show error message */
            stopLoading(btn);
            shake(btn);
          });
      });
    } else {
      uploadFieldRef.current?.click();
    }
  }

  function handleFilePick(event) {
    const file = event.target.files[0];
    if (!file) return;
    const trashBtn = trashBtnRef.current;
    const uploadBtn = trashBtn?.previousSibling;
    if (!trashBtn || !uploadBtn) return;

    swapLabel(uploadBtn, () => {
      setFileToUpload(file);
      trashBtn.style.display = "flex";
    });
  }

  async function clearFile() {
    if (confirmDelete) await cancelDelete();

    const trashBtn = trashBtnRef.current;
    if (trashBtn) {
      const uploadBtn = trashBtn.previousSibling;
      if (uploadBtn) {
        swapLabel(uploadBtn, () => setFileToUpload(null));
      }
      trashBtn.style.maxWidth = trashBtn.clientWidth;
      await pause(10);
      trashBtn.style.maxWidth = 0;
      trashBtn.style.opacity = 0;
      await pause(200);
      trashBtn.style.display = "none";
      trashBtn.style.maxWidth = "";
      trashBtn.style.opacity = "";
    }
    const uploadField = uploadFieldRef.current;
    if (uploadField) {
      uploadField.value = null;
    }
  }

  async function handleSignOut() {
    await axios
      .post(`${ServerUrl}/users/logout`, {}, { withCredentials: true })
      .catch();
    navigate("/home");
  }

  async function showDeleteConfirm() {
    /** @type {Element} */
    const deleteBtn = deleteBtnRef.current;
    /** @type {Element} */
    const deleteWarn = deleteWarnRef.current;
    if (!deleteBtn || !deleteWarn) return;

    const fullHeight = deleteWarn.scrollHeight;
    deleteWarn.style.maxHeight = "0px";
    swapLabel(deleteBtn, () => {
      deleteWarn.style.maxHeight = `${fullHeight}px`;
      setShowCancelBtn(true);
      setConfirmDelete(true);
    });
    await pause(100);
    deleteWarn.style.maxHeight = null;
    if (deleteBtn.matches(":focus-visible")) {
      deleteBtn.nextElementSibling?.focus();
    }

    await pause(200);
    const scrollElt = getScrollElement(contentElement);
    if (scrollElt) {
      const { scrollHeight, clientHeight, scrollTop } = scrollElt;
      const scrollDistance = scrollHeight + clientHeight - scrollTop;
      zenScroll
        .createScroller(scrollElt)
        .toY(scrollElt.scrollHeight, scrollDistance);
    }
  }

  async function cancelDelete() {
    /** @type {Element} */
    const deleteBtn = deleteBtnRef.current;
    /** @type {Element} */
    const deleteWarn = deleteWarnRef.current;
    if (!deleteBtn || !deleteWarn) return;

    setShowCancelBtn(false);
    deleteWarn.style.maxHeight = `${deleteWarn.clientHeight}px`;
    swapLabel(deleteBtn, () => {
      setConfirmDelete(false);
      deleteWarn.style.maxHeight = "0px";
    });
    await pause(100);
    deleteWarn.style.maxHeight = null;
    if (deleteBtn.nextElementSibling?.matches(":focus-visible")) {
      deleteBtn.focus();
    }
  }

  async function handleDeleteAccount() {
    if (userInfo) {
      await axios
        .delete(`${ServerUrl}/users/${userInfo.participantId}`, {
          withCredentials: true,
        })
        .then(() => {
          handleSignOut();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  async function handleReturn() {
    if (confirmDelete) {
      cancelDelete();
      await pause(250);
    }
    swapContent(contentElement, async () => {
      await pause(100);
      setIsOpen(false);
      setFileToUpload(null);
      setUploadSuccess(false);
      setConfirmDelete(false);
      await pause(100);
    });
  }

  return (
    isOpen && (
      <>
        <Helmet>
          <title>Researcher Tools</title>
        </Helmet>
        <div className="btn-group">
          <Button
            variant="secondary"
            icon={
              <IconArrowLeft
                style={{ boxSizing: "border-box", paddingRight: "0.2rem" }}
                stroke={2.5}
              />
            }
            label="Return to Profile"
            styles={{ width: "100%" }}
            onClick={handleReturn}
          />
          <span className="file-upload">
            <Button
              className="upload-xlsx"
              label={
                uploadSuccess
                  ? "Upload Successful"
                  : fileToUpload
                    ? fileToUpload.name
                    : "Upload Excel File"
              }
              variant="light"
              styles={{ width: "100%" }}
              icon={
                fileToUpload ? (
                  <IconFile />
                ) : uploadSuccess ? (
                  <IconCheck />
                ) : (
                  <IconUpload />
                )
              }
              onClick={!uploadSuccess && handleUpload}
              tippy={
                fileToUpload && {
                  content: fileToUpload ? fileToUpload.name : "no file",
                  disabled: !fileToUpload,
                }
              }
            />
            <Button
              className="upload-discard"
              variant="secondary"
              icon={<IconTrash />}
              onClick={clearFile}
              tippy={{ content: "Clear File" }}
              ref={trashBtnRef}
            />
          </span>
          <input
            id="file-upload"
            type="file"
            accept=".xlsx"
            style={{ display: "none" }}
            onChange={handleFilePick}
            ref={uploadFieldRef}
          />
          <header
            className={clsx("delete-warn", !confirmDelete && "hidden")}
            ref={deleteWarnRef}
          >
            <IconExclamationCircle height="3em" width="3em" stroke={1.3} />
            <div className="msg">
              <b>This action cannot be undone.</b>
              Are you sure you want to delete your account?
            </div>
          </header>
          <div className="btn-row">
            <Button
              icon={
                !confirmDelete && (
                  <IconTrash
                    style={{
                      boxSizing: "border-box",
                      paddingRight: "0.4rem",
                    }}
                    stroke={2.4}
                  />
                )
              }
              label={confirmDelete ? "Yes, Delete" : "Delete Account"}
              styles={{ width: "100%" }}
              bgColor="var(--danger-color)"
              onClick={confirmDelete ? handleDeleteAccount : showDeleteConfirm}
              ref={deleteBtnRef}
            />
            <Button
              label="Cancel"
              variant="secondary"
              onClick={cancelDelete}
              styles={{
                marginLeft: showCancelBtn ? "0px" : "-1rem",
                opacity: showCancelBtn ? 1 : 0,
                flex: showCancelBtn ? 1 : 0,
                maxWidth: "fit-content",
              }}
              tabIndex={!showCancelBtn && -1}
            />
          </div>
        </div>
      </>
    )
  );
}

export default ResearcherTools;
