import { createContext } from "react";
import { updateDisplayInfo } from "./DisplayInfo";

export const DisplayInfo = createContext(updateDisplayInfo());

/** @type {React.Context<ScenarioStates>} */
export const ScenarioInfo = createContext({});

/** @type {React.Context<ModalState>} */
export const ModalContext = createContext({});
