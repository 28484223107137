import { IconLoader2 } from "@tabler/icons-react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ServerUrl, loadFor } from "../Utils";

export const ProtectedRoute = ({ children }) => {
  const [tokenValid, setTokenValid] = useState(false);
  const [verifyComplete, setVerifyComplete] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    loadFor(500, () => {
      return axios.post(
        `${ServerUrl}/users/current-user/`,
        {},
        { withCredentials: true },
      );
    })
      .then(() => setTokenValid(true))
      .catch(() => setTokenValid(false))
      .finally(() => setVerifyComplete(true));
  }, []);

  useEffect(() => {
    if (verifyComplete && !tokenValid) {
      navigate("/unauthorized");
    }
  }, [verifyComplete]);

  return tokenValid ? children : <IconLoader2 className="token-verifying" />;
};

export default ProtectedRoute;
