import { pause } from "../Utils";
import { Button } from "./Button";
import CustomModal from "./Modal";
export default function ResearcherModal(props) {
  async function handleCancel(event) {
    event.preventDefault();
    closeModal();
  }

  async function closeModal() {
    await pause(100);
    props.setModalState({ action: null });
    await pause(200);
  }

  const messages = {
    reminder: {
      title: "Send Reminder",
      content: "This action will send reminder users to complete the test.",
    },
    enablePostTest: {
      title: "Enable Post-Test",
      content: "This action will enable post test functionality for users.",
    },
    "Activated Users": {
      title: "Activate Users",
      content:
        "This action will remind users who have been sent activation link, but have not activated thier accounts.",
    },
    "Completed Pre-Test": {
      title: "Remind Users to complete Pre-Test",
      content:
        "This action will remind users who are eligible to attend Pre-Test, but yet to complete the Pre-Test Task",
    },
    "Completed Training": {
      title: "Remind Users to finish Training",
      content:
        "This action will remind users who's current task is Training, but yet to complete the training module.",
    },
    "Completed Post-Test": {
      title: "Remind Users to finish Post Test",
      content:
        "This action will remind users who's current task is Post Test, but yet to complete the post-test module.",
    },
  };

  return (
    <>
      <CustomModal
        id="login-modal"
        className="login-modal"
        title={"Actions"}
        contentClassName="login-form"
        contentLabel="Researcher Modal"
        customWidth="40vw"
        isOpen={props.modalState != null}
        close={handleCancel}
        focusOnOpen
      >
        <h2>{messages[props.modalState.action].title}</h2>
        <div style={{ fontSize: "1.1rem", textAlign: "center", opacity: 0.9 }}>
          {messages[props.modalState.action].content}
        </div>
        <div
          style={{
            marginBottom: "1rem",
            fontSize: "1.1rem",
            textAlign: "center",
            opacity: 0.9,
          }}
        >
          Note: This action was last perform on 04/01/2023
        </div>

        <Button
          reverse
          className="qa-btn"
          label={`${messages[props.modalState.action].title}`}
          variant="light"
          onClick={() => continueModule()}
        />
      </CustomModal>
    </>
  );
}
