import { useState } from "react";
import { pause } from "../Utils";
import FeedbackForm from "./FeedbackForm";
import Modal from "./Modal";

/** @param {{ modalState: UseState<boolean> }} */
const FeedbackModal = ({ modalState }) => {
  const [formOpen, setFormOpen] = useState(true);
  const [modalOpen, setModalOpen] = modalState;

  async function closeModal(event) {
    await pause(100);
    event.preventDefault();
    setModalOpen(false);
    await pause(200);
  }

  return (
    <Modal
      id="feedback-modal"
      className="feedback-modal"
      title={"Help & Feedback"}
      contentClassName="feedback-form"
      contentLabel="Feedback Modal"
      isOpen={modalOpen}
      close={closeModal}
      focusOnOpen
      scrollVisible="var(--show-scroll)"
    >
      <FeedbackForm
        modalState={modalState}
        formOpenState={[formOpen, setFormOpen]}
      />
    </Modal>
  );
};

export default FeedbackModal;
