import axios from "axios";
import { ClickScrollPlugin, OverlayScrollbars } from "overlayscrollbars";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { useState } from "react";
import { exportToExcel } from "react-json-to-excel";
import { ServerUrl } from "../../Utils";
import { Button } from "../../components/Button";
import Card from "../../components/Card";
import DonutGraphCont from "../../components/DonutGraph";
import ResearcherModal from "../../components/ResearcherModal";
import "../../styles/research-dashboard.css";

export default function ResearchDashboard() {
  document.body.id = "research-dash";

  const handleClick = () => {
    console.log("Hello");
  };

  const [graphData, setGraphData] = useState([
    [10, 3],
    [3, 9],
    [6, 7],
    [8, 2],
  ]);

  const [modalState, setModalState] = useState({
    action: null,
    state: null,
  });
  const data = [
    {
      sheetName: "EXPERIMENT",
      details: [
        { participantId: "10001", age: "20", sex: "M" },
        { participantId: "10002", age: "22", sex: "F" },
        { participantId: "10003", age: "20", sex: "M" },
      ],
    },
    {
      sheetName: "EXPERIMENT_AM",
      details: [
        { participantId: "10006", age: "20", sex: "M" },
        { participantId: "10007", age: "22", sex: "F" },
        { participantId: "10008", age: "20", sex: "M" },
      ],
    },
  ];
  document.body.id = "research-dash";
  OverlayScrollbars.plugin(ClickScrollPlugin);

  async function exportUsers() {
    const url = new URL(`${ServerUrl}/users/reports`);
    const requestOptions = {
      method: "GET",
      withCredentials: true,
      redirect: "follow",
    };

    const userData = await axios(url.href, requestOptions)
      .then(function (response) {
        console.log(response.data);
        return response.data.data;
      })
      .catch(function (error) {
        console.error(error);
      });
    exportToExcel(userData, "user_data", true);
  }

  return (
    <OverlayScrollbarsComponent
      className="scroll-container"
      options={{
        scrollbars: {
          clickScroll: true,
        },
      }}
      defer
    >
      <Card className="card-cont" width="auto">
        <div className="card-cont-title">User Analytics:</div>
        <Card className="cards-wrapper" width="auto">
          <DonutGraphCont
            title="Activated Users"
            subTitle="Total: 1020"
            data={graphData[0]}
            onClick={exportUsers}
            setModalState={setModalState}
            buttonLabel={"Download User Data"}
          />
          <DonutGraphCont
            title="Completed Pre-Test"
            subTitle="Total Users in Pre-Test: 601"
            data={graphData[1]}
            onClick={handleClick}
            buttonLabel={"Send Reminder"}
            setModalState={setModalState}
            disabled
          />
          <DonutGraphCont
            title="Completed Training"
            subTitle="Total Users in Training: 404"
            data={graphData[2]}
            onClick={handleClick}
            buttonLabel={"Send Reminder"}
            setModalState={setModalState}
            disabled
          />
          <DonutGraphCont
            title="Completed Post-Test"
            subTitle="Total Users in Post-Test: 404"
            data={graphData[3]}
            buttonLabel={"Send Reminder"}
            onClick={handleClick}
            setModalState={setModalState}
            disabled
          />
        </Card>
      </Card>

      {typeof setModalState === "function" && modalState.action != null && (
        <ResearcherModal
          modalState={modalState}
          setModalState={setModalState}
        />
      )}
      <div className="section-seperator" />
      <Card className="card-cont" width="auto">
        <div className="card-cont-title">Quick Actions:</div>
        <div className="cards-wrapper">
          {/* <Card className="qa-card"> */}
          <Button
            reverse
            className="qa-btn"
            label={"Send Email"}
            variant="dark"
            bgColor="var(--default-card-bg-color)"
            onClick={() => continueModule()}
          />
          {/* </Card> */}
          {/* <Card className="qa-card"> */}
          <Button
            reverse
            className="qa-btn"
            label={"Deactivate Users"}
            variant="dark"
            bgColor="var(--default-card-bg-color)"
            onClick={() => continueModule()}
            tippy={{
              content: "Send Email Reminder",
              placement: "top",
            }}
          />
          {/* </Card>
          <Card className="qa-card"> */}
          <Button
            reverse
            className="qa-btn"
            label={"Batch Post-Test Activation"}
            variant="dark"
            bgColor="var(--default-card-bg-color)"
            onClick={() => continueModule()}
          />
          {/* </Card>
          <Card className="qa-card"> */}
          <Button
            reverse
            className="qa-btn"
            label={"Download CSV Data for All Users"}
            variant="dark"
            bgColor="var(--default-card-bg-color)"
            onClick={() => continueModule()}
          />
          {/* </Card>
          <Card className="qa-card"> */}
          <Button
            reverse
            className="qa-btn"
            label={"Search Users by Filters"}
            variant="dark"
            bgColor="var(--default-card-bg-color)"
            // onClick={() => continueModule()}
            onClick={async () => {
              const userData = await fetchUserDataToExport();
              exportToExcel(userData, "user_data.xlsx", true);
            }}
          />
          {/* </Card> */}
        </div>
      </Card>
    </OverlayScrollbarsComponent>
  );
}
